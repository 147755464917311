

const en = {

    form: {
        nameRequired: "Name is required",
        createProcess: {
            industryRequired: "Industry is required",
            invalidSelection: "Invalid industry selection",
            processOverview: "Process Overview",
            processNameAlreadyExist: "Process name already exist",
            processCannotCreate: "process cannot be create by its user",
            selectIndustry: "Select Industry",
            selectLanguage: "Select interview language",
        },
        createGroup: {
            createGroup: "Create Folder",
            folderNameExist: "Folder name already exist",
            groupCreateSuccessfully: "Folder create successfully",
            selectOwner: "Select Owner",
            youNotAuthorized: "You are not authorized to create a folder"
        },
        createCompany: {
            createACompany: "Create a company",
            companyName: "Company name",
            postalCode: "Postal code ",
            phoneNumber: "Phone number",
            website: "Website"
        }

    },
    features: {
        swimlane: {
            approveQuestion: "Are you sure you want to approve this diagram?",
            disApproveQuestion: "Êtes-vous sûr de vouloir désapprouver ce diagramme ?",
            exportPDF: "Export PDF",
            exportPNG: "Export PNG",
            oops: " Oops! Something Went Wrong",
            pleaseWait: "Please wait, your map is currently being prepared.",
            // eslint-disable-next-line no-template-curly-in-string
            s1: "As a process owner, you've saved a whopping {{time}} minutes on interviews!",
            // eslint-disable-next-line no-template-curly-in-string
            s2: "{{time}} interview minutes reclaimed! Famla empowers process owners like you.",
            // eslint-disable-next-line no-template-curly-in-string
            s3: "{{time}} minutes saved on interviews. Process owners, Famla streamlines your work.",
            sucessApprov: "Success! The diagram has been successfully approved.",
            sucessDisapprov: "Success! The diagram has been successfully rejected.",
            versionHistory: "Version History",
            WeSorry: "We're sorry, but your swimlane diagram couldn't be displayed. This issue can occur for various reasons, such as network connectivity problems, server issues, or incorrect data formatting."
        }
    },

    navigation: {
        allFieldsRequired: "All fields marked with (*) are required.",
        anErrorOcured: "An error has occurred please try again later",
        anErrorOcuredProcessNotFund: "An error occurred. Process not found.",
        anErrorOccurredMappNotFound: "An error occurred. mappnot found",
        assigningProcessFolder: "Assigning a process to a folder",
        approvalCannotCreateByUser: "You cannot request approval as a collaborator.",
        backHome: "Back home",
        contactUs: "Contact Us",
        diagramName: "Diagram name",
        diagramsPendingApproval: "Diagrams Pending Approval",
        enterValideEmail: "Enter a valide email",
        folderSuccessfully: "Folder successfully updated.",
        folderNotFound: "Error: Folder was not found",
        inviteOwner: "Invite a process owner",
        inviteAApprover: "Invite a approver",
        inviteCollaborator: "Invite a collaborator",
        invitationSentSuccessfully: "Invitation sent successfully",
        logOut: 'Log out',
        markAsFinal: "Mark As Final",
        mapWithNotFound: "An error occurred: Map with not found.",
        noRequest: "No Requests to Approve",
        privacyHub: 'Privacy Hub',
        shareLink: "Share link",
        selectFolder: "Select folder",
        successfullyUpdated: "Successfully updated",
        termsOfUse: "Terms Of Use",
        theProcessHasBeenHalted: "The process has been successfully halted.",
        privacyPolicy: "Privacy Policy",
        processClassifiedWithSuccess: "Process classified with success",
        processCannot: "You are not authorized to update this process.",
        processNotFound: "The process could not be found.",
        proJectNotFound: "The project could not be found.",
        publishQuestion: "Mark as final? This will make the Process read-only and prevent further editing.",
        youAreNotLoggedIn: "You are not logged in",
        youAreNotAuthorized: "You are not authorized to upgrade this Folder.",
        youAreNotAuthorizedToPerformThisAction: "You are not authorized to perform this action."
    },

    layout: {
        header: {
            approvalRequests: "Approval Requests",
            upgradeToPremium: " Upgrade to premium",
            updateCompany: 'Update Company',


        }
    },

    views: {
        auth: {
            accountAlreadyExist: "Account already exist",
            alreadyHaveAccount: "Already have an account ?",
            anErrorOcured: "An error has occurred please try again later",
            compagnyNameIsRequired: "Compagny name is required",
            continueWithApple: "Continue with Apple",
            continueWithGoogle: "Continue with Google",
            continueWithMicrosoft: "Continue with Microsoft",
            dontHaveAccount: "Don't have an account ?",
            eliminate: "Eliminate Waste, Slash Costs and Maximise Profits with proven Lean Six Sigma methodologies.",
            emailAddres: "Email Address",
            emailIsRequired: "Email is required",
            emailAddressIs: "Email address is incorrect or does not exist",
            establish: "Establish clear roles & responsibilities, remove ambiguity, optimize collaboration and drive team's success.",
            forgotPassword: "Forgot your password?",
            getStarted: "Get Started",
            incorrectEmailOrPassword: "Invalid email or password",
            logIn: "Log In",
            msgReset: "We will send you instructions for resetting your password upon entering your email address",
            newPassword: "New password",
            newPassWordIsRequired: "newPassword is required",
            password: "Password",
            passwordsNotMatch: "Passwords do not match",
            passwordIsRequired: "Password is required",
            passwordSuccessfully: "Password successfully reset",
            resetYourPassword: 'Reset your password',
            reset: 'Reset',
            signUp: 'Sign Up',
            seamlessly: "Seamlessly document your processes and achieve ISO 9001:2015 compliance with confidence.",
            thisEmailHasBanned: "This email address has been banned from Famla",
            userDoesNotExist: "User does not exist",
            verifyYourEmail: "Please verify your email address",
            yourSessionHasExp: "Your session has expired or you are logged in on another device."

        },
        processView: {
            areYouSure: "Are you sure you want to delete this item?",
            folder: "Folder",
            myProcess: 'My processes',
            newProcess: 'New process',
            needInspiration: "Need inspiration ? Explore these popular use cases",
            library: "Library",
            popularUseCase: "Popular use cases",
            processNotFound: "Process not found",
            viewAll: 'View all'


        },
        processInterview: {
            addCollaborators: "Add Collaborators",
            collaborators: "Collaborators",
            contributor: "Contributor",
            comment: 'Comment',
            diagramsList: "Diagrams list",
            mapReady: "Map ready in 10 minutes",
            newProcess: "New process",
            reply1: "End of the interview thanks for your contribution.",
            reply2: "End of the interview thanks for your contribution. We will reach out to the team and come back to you later",
            reply3: "I have completed the interviews, click on generate map to view the result",
            reply4: "Please can you list missing collaborator?",
            reply5: "Is there anything else you would like to add or any additional information you think would be valuable for us to consider?",
            recentProcesses: "Recent processes",
            // eslint-disable-next-line no-template-curly-in-string
            s1: "Your process map is ready!-As a process owner, you've saved a whopping {{time}} minutes on interviews!",
            // eslint-disable-next-line no-template-curly-in-string
            s2: "Your process map is ready!-{{time}} interview minutes reclaimed! Famla empowers process owners like you.",
            // eslint-disable-next-line no-template-curly-in-string
            s3: "Your process map is ready!-{{time}} minutes saved on interviews. Process owners, Famla streamlines your work.",
            yourResponse: "Response",

        },

        processByGroupView: {
            approvalName: 'Approver name',
            approvalDate: 'Approval date',
            dateFormat: "Date format must be YYYY-MM-DD HH:MM:SS",
            diagramLink: "Diagram link",
            formatDate: "YYYY-MM-dd HH:mm:ss",
            interviewTime: "Interview Time saved",
            lastModifiedDate: "Last modified date",
            noProcess: "No process",
            nexRevisionDate: "Next revision date",
            ownerName: "Owner's name",
            processName: "Process name",
            processMetrics: "Process Metrics",
            revisionTriggers: "Revision Triggers"


        },

        legalDocs: {
            contact: {
                eitherFill: "You can fill in the form or use the contact details opposite.",
                gettingInTouch: "Getting in touch is easy!",
                sendMeCopy: "Send me a copy",
            },

            faq: {
                contactUsPage: 'contact Us page',
                p1: "Think of your processes as your organization's DNA – they're the blueprint for how things get done! Famla was created to help organizations of all sizes, industries, and locations understand, document, and streamline those essential workflows. So, what makes Famla the perfect fit for you? It's all about simplicity, speed, and a powerful AI improvement engine that helps you work smarter, not harder",
                p2: "This initial version focuses on cross-functional swimlane diagrams. These are perfect for visualizing how work flows across different teams or departments. We'll be adding more diagram types soon!",
                p3: "Yes! We support common notations like BPMN (Business Process Model and Notation) to ensure consistency and easy understanding.",
                p4: "Of course. Export your process maps as images (PNG, JPEG) or PDFs – perfect for reports, presentations, or getting feedback. You can also invite people to view the finished maps directly inside the tool for quick collaboration.",
                p5: "Absolutely! We built this tool with teamwork in mind. Easily invite team members to collaborate on maps in real-time, making process improvement a shared effort.",
                p6: " Not yet, but it's coming soon! We know how important it is to keep everything organized, so we're working on the ability to attach files, links, notes, and more directly within your maps.",
                p7: "Get ready for some process magic! Our AI engine analyzes your maps using Lean Six Sigma principles to pinpoint areas where you can streamline and improve.",
                p8: "Definitely. We take data security seriously. Our platform is hosted on Google Cloud, giving you top-notch security with encryption and all the latest safeguards.",
                p9: "After signing up, one of our team members will reach out to chat about your needs and the best subscription fit. In the meantime, dive right in, explore the tool and experience Famla's power firsthand!",
                p10: "Our friendly support team is here for you! Reach out anytime through our ",
                p11: "We're constantly working to improve Famla!  Let us know what features you'd love to see next.",
                t1: "Is Famla the perfect match for my business? ",
                t2: "What kind of process maps can I build with this tool?",
                t3: "Does this tool work with the process mapping standards I already know?",
                t4: "Can I easily share my finished maps with others?",
                t5: "Can my team and I work on a process map together?   ",
                t6: "Can I connect other information to my process maps?",
                t7: "How can this tool help me improve my processes? ",
                t8: "Is my process map data secure?",
                t9: "Do you offer a free subscription or a trial period?",
                t10: "How can I get help if I need it? ",
            },

            privacy: {
                p1: "At Famla AI, we are deeply committed to the privacy and security of your business's data. We understand the critical role data integrity plays in the successful deployment of AI solutions, and we have implemented robust measures to ensure its protection. This policy outlines the principles guiding our data collection, use, and security practices.",
                p2: "We may integrate with third-party services to enhance certain features of our AI solution. These third-parties will have access only to the data necessary to perform their specific functions on our behalf. We require strict privacy and security safeguards from all our partners.",
                p3: "As our AI solutions and privacy regulations evolve, we will revise this policy. You will be notified of any significant changes, and the updated policy will be published on our website.",
                p4: "If you have questions about our Privacy and Security Policy, please contact us at",
                privacyAndSecurityPolicy: "Privacy and Security Policy",
                t1: "Our Commitment",
                t2: "Information We Collect",
                t3: "How We Use Your Data",
                t4: "Security Measures",
                t5: "Protection of your rights",
                t6: "Third-Party Services",
                t7: "Policy Updates",
                ulLi1: "Data Required for operations: To provide our AI services, we will collect the minimum necessary data to train, improve, and deliver insights through our models. This data may include organogram, customer name, activities performance data.",
                ulLi2: "Usage Data: We may collect anonymous usage data to understand how our AI solution is used, allowing us to optimize its performance and tailor it to your business needs.",
                ulLi3: "Essential Account Information To manage your account and provide customer support, we may collect basic contact information such as your company name, address, and authorized user email addresses.",
                ulLi4: "Service Improvement: We may use aggregated and anonymized usage data to understand and improve our AI solution for all our clients.",
                ulLi5: "Customer Support: We may use your account information to provide technical support, communicate with your authorized users, and respond to your inquiries.",
                ulLi6: "Tailored Marketing and Insights: We may analyze anonymized and aggregated data derived from your usage to provide more relevant industry insights, trends, and potentially advertisements related to our services or partner offerings.",
                ulLi7: "Encryption: We employ industry-standard encryption methods to protect your data both in transit and at rest.",
                ulLi8: "Secure Data Storage: Our AI solution is hosted on secure, professionally managed cloud infrastructure that undergoes regular security audits.",
                ulLi9: "Access Controls: Access to your data is strictly controlled, granted only to authorized personnel who are contractually bound by confidentiality agreements.",
                ulLi10: "Incident Response: We have a detailed incident response plan in place to address potential security breaches swiftly. We will notify you in accordance with any applicable regulations should a breach occur",
                ulLi11: "Data Transparency: We provide clear information about how your data is used within this policy and in any contractual agreements.",
                ulLi12: "Data Access: You have the right to access the data we collect and process on your business's behalf.",
                ulLi13: "Data Rectification: You have the right to request the correction of any inaccurate personal data held by us.",
                ulLi14: "Data Deletion: Upon termination of your service agreement, you can request the deletion or return of your data in accordance with applicable regulations.",
            },

        }


    },

    words: {
        address: "Address",
        approve: "Approve",
        cancel: 'Cancel',
        close: 'Close',
        comments: "Comment",
        country: "Country",
        create: "Create",
        city: "City",
        description: 'Description',
        delete: "Delete",
        disApprove: "Reject",
        edit: "Edit",
        email: "Email",
        english: "English",
        faq: "FAQ",
        french: "French",
        file: "File",
        help: 'Help',
        invite: "Invite",
        languages: "Languages",
        link: "Link",
        me: 'Me',
        message: "Describe your request here",
        name: 'Name',
        no: "no",
        organize: "Move Folder",
        or: "OR",
        refresh: "Refresh",
        rename: "Rename",
        requestApproval: "Request approval",
        role: "Role",
        publish: "Publish",
        process: 'Processes',
        save: "Save",
        share: "Share",
        searching: "Searching",
        search: "Search",
        send: "Send",
        subject: "Subject",
        yes: "yes"



    },

}

export default en
