//React component
import React from 'react'

//app component
import { AppLayout } from '../../layouts'
import { Images } from '../../constants'
import { useAppSelector } from '../../stores'

const MailVerification = () => {
    const users = useAppSelector((state) => state.auth.user);


    return (
        <AppLayout>
            <div className={`container p-5 text-center text-start align-items-center justify-content-center `}>

                <div className="pb-5" style={{ marginTop: 100 }}>
                    <span> <i className="fa-regular fa-envelope text-white my-5" style={{ fontSize: 100 }}></i></span>
                    <div>
                        <img className='img-fluid' src={Images.logov1} alt="famla" style={{ width: 140 }} />
                        <div className='mt-3'>
                            <span className='text-white'>Hi <span className='text-theme'>{users?.fullname} </span></span>
                            <p className="mb-4 fs-6 text-white">You're one click away from unlocking amazing features!<br /> Please verify your email by clicking the link in your inbox.</p>
                        </div>
                        <p className='text-white mt-3'>Thanks, <br /> Famla Team </p>
                    </div>
                </div>

            </div>
        </AppLayout>
    )
}

export default MailVerification