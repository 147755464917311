import React from 'react'

interface childProps {
    msg: string
    type: string
    clasName?: string
}

const Alert = (props: childProps) => {
    const { msg, type, clasName } = props
    return (
        <div className={`alert alert-${type} alert-dismissible fade show my-2 ${clasName}`} role="alert">
            <strong>{msg}</strong>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    )
}
export default Alert