import React from 'react'

interface InputProps {
    formClass?: string
    iconClass?: string
    iconName?: string
    inputClass?: string
    inputContainerClass?: string
    inputStyle?: React.CSSProperties
    inputType: string
    inputValue?: string
    onChange(event: React.ChangeEvent<HTMLInputElement>): any
    placeholderValue?: string
    iconContainer: string
}

const SearchInput = (props: InputProps) => {
    const { formClass, iconClass, inputClass, inputContainerClass, inputStyle, inputType, inputValue, onChange, placeholderValue, iconContainer } = props

    return (
        <div className={`${inputContainerClass}`}>
            <div className={`input-group mb-3 form-control ${formClass}`} style={inputStyle}>
                <span className={`input-group-text ${iconContainer}`} id="basic-addon1"><i className={`${iconClass}`}></i></span>
                <input onChange={onChange} type={inputType} className={`custom-input ${inputClass}`} placeholder={placeholderValue} value={inputValue} aria-label="Username" aria-describedby="basic-addon1" />
            </div>
        </div>
    )
}
export default SearchInput