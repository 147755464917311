// React Component
import React from 'react'
import { useTranslation } from 'react-i18next'

// App Component
import { CardProcess } from '../../components'
import { AppLayout, HeaderLayout, ProcessLayout } from '../../layouts'
import { FamlaServices } from '../../services'
import { useAppSelector } from '../../stores'
import { Spiner } from '../../components'

// Features


//interface
import { iProject } from '../../interfaces';

interface iState {
    isLoadingProject: boolean,

}


const GroupOfProcessViewAll = () => {
    const { t } = useTranslation()
    const users = useAppSelector((state) => state.auth);
    const [processNumbers, setProcessNumbers] = React.useState<{ [key: string]: number }>({});
    const [project, setProject] = React.useState<iProject[]>([]);
    const [state, setState] = React.useState<iState>({
        isLoadingProject: true,
    })



    const getProcessNumber = async (projectId: string) => {
        try {
            let data = await FamlaServices("api_host", `/project/number/${projectId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                return data.body.data;
            } else {
                return 0;
            }
        } catch (e) {
            return 0;
        }
    };

    React.useEffect(() => {
        const getGroupOfProcessByUser = async () => {
            let data = await FamlaServices("api_host", `/project/my`, "GET", "", users.access_token);
            if (data.status === 200) {
                let projects = data.body.data

                const numbers: { [key: string]: number } = {};
                for (const project of projects) {
                    numbers[project._id] = await getProcessNumber(project._id);
                }
                setProcessNumbers(numbers);
                setProject(projects)
                setState((prev) => ({ ...prev, isLoadingProject: false }))

            }
        }

        getGroupOfProcessByUser()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users.access_token])

    const deleFolder = async (folder_id: string, name: string) => {
        if (name !== "Default") {
            setProject(project.filter((project: { _id: string }) => project._id !== folder_id));
            await FamlaServices("api_host", `/project/${folder_id}`, "DELETE", "", users.access_token);
        }
    }



    return (
        <AppLayout>
            <div className="container-fluid mt-3 pb-3 px-5">
                <HeaderLayout loadcompany={false} />
                <div className="mt-4 m-auto d-flex align-items-center justify-content-center w-75">
                </div>

                <ProcessLayout title={t('views.processView.library')} arrowBack={true} style={{}} addClassName={` containerCardProcess overflow-auto${project.length <= 5 ? 'vh-100' : ''}`}>
                    {state.isLoadingProject ? (
                        <Spiner className='ms-5 mt-5' />
                    ) : (
                        <>
                            {project.map((project: iProject, index: number) => (
                                <CardProcess
                                    processName={project.title}
                                    thisProcessHasABadge={processNumbers[project._id] > 0}
                                    theBadgeHasAValue={true}
                                    badgeValue={processNumbers[project._id] || 0}
                                    thisProcessHasAOwner={true}
                                    owner=''
                                    key={index}
                                    route=''
                                    deleteProcess={() => { deleFolder(project._id, project.title) }}
                                    description={project.description}
                                    processId={project._id}
                                    folder={true}
                                    setProject={setProject}
                                />
                            ))}
                        </>


                    )}
                </ProcessLayout>
            </div>
        </AppLayout>
    )
}

export default GroupOfProcessViewAll