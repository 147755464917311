// React Component
import React, { useRef } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"


// App Component
import { Routes } from "../routes"
import { Images } from "../constants"
import { FamlaServices } from "../services"
import { useAppSelector } from "../stores"
import { Dropdown, InputForm, Spiner } from "../components"
import { i18n } from '../services'

//interface
import { iAlert } from "../interfaces"
import { ApprovalRequestModal } from "../views/modal"

interface ChildProps {
    loadcompany: boolean
    listApproval?: Array<any>
    loading?: boolean
}

const HeaderLayout = (props: ChildProps) => {
    const { loadcompany, listApproval, loading } = props
    const users = useAppSelector((state) => state.auth);
    const closeRef = useRef<HTMLButtonElement>(null);
    const defaultProjectId: string = "b15abbcd-4b91-407a-acf4-58eb7fafbdea";
    const [currentLanguage, setCurrentLanguage] = React.useState(() => {
        try {
            return localStorage.getItem('currentLanguage') || navigator.language;
        } catch (error) {
            return navigator.language;
        }
    });
    const [state, setState] = React.useState({
        email: "",
        isSubmit: false,
        myCompany: [],
        spinner: true,
    })
    const { t } = useTranslation();
    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })

    React.useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language: string) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);
        // let action = {
        //     type: "LANGUAGE",
        //     value: {
        //         language: updateLanguage,
        //         languageName: updateLanguage === 'fr' ? t('words.french') : t('words.english')
        //     }
        // };
        // dispatch(action);

        // updateLangByusers(updateLanguage);
        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };



    const handleChange = (event: any) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'email') {
            setState((prev) => ({ ...prev, email: fieldValue }))
        }
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (state.email.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.enterValideEmail') });
            return;
        }
        let formData = {
            project_id: defaultProjectId,
            mail: [state.email],

        }

        setState({ ...state, isSubmit: true });
        let data = await FamlaServices("api_host", `/project/add_owner/`, "PUT", formData, users.access_token);

        if (data.status === 200) {
            setAlert({ isActive: true, type: 'success', message: t('navigation.invitationSentSuccessfully') });

        } else if (data.status === 404) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.proJectNotFound') });
        }
        else if (data.status === 401) {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.youAreNotLoggedIn") });
        }
        else {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcured") });
        }
        setState({ ...state, isSubmit: false });
    };

    React.useEffect(() => {
        const loadMyCompany = async () => {
            let data = await FamlaServices("api_host", `/company/my/`, "GET", "", users.access_token);
            if (data.status === 200) {
                setState({ ...state, myCompany: data.body.data, spinner: false });
            }
        }
        if (loadcompany) {
            loadMyCompany();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.myCompany.length, users.access_token])

    const switchToNewCompany = async (company_id: string) => {
        let form = {
            company_id: company_id,
            interview_id: ""
        }
        let data = await FamlaServices("api_host", `/session/`, "PUT", form, users.access_token);
        setState({ ...state, isSubmit: true });
        if (data.status === 200) {
            setState({ ...state, isSubmit: false });
            window.location.replace(Routes.DASHBOARD)

        }
    }

    return (
        <>
            <header className=" navbar navbar-expand-lg navbar-light bg-black w-100">
                <div className='m-none navbar-brand'>
                    <img src={Images.logov1} height={30} alt="" />
                </div>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 text-primary text-uppercase">

                </ul>
                <div className="d-flex flex-row align-items-center">
                    <Link to={Routes.CONTACTUS} className="fs-6 text-decoration-none pe-3 text-theme me-2">
                        👑 {t('layout.header.upgradeToPremium')}
                    </Link>

                    <Dropdown
                        key={2}
                        dropName={currentLanguage === "fr" ? t('words.french') : t('words.english')}
                        dropdownType="dropdown"
                        iconClassName='fa-solid fa-globe me-2'
                        buttonDropClassName='btn btn-secondary bg-black border-0 m-none'
                        styleButton={{}}
                        theRouteOfAction4={''}
                        theRouteOfAction5={''}
                        lang={true}
                        changeLanguage={changeLanguage}
                    />

                    <Dropdown
                        key={2}
                        dropName={t('words.help')}
                        dropdownType="dropstart"
                        iconClassName=''
                        buttonDropClassName='btn btn-secondary bg-black border-0 m-none'
                        styleButton={{}}
                        deleteProcess={() => { }}
                        action4={t('navigation.contactUs')}
                        action5={t('navigation.privacyHub')}
                        theRouteOfAction4={Routes.CONTACTUS}
                        theRouteOfAction5={Routes.PRIVACY}
                        faq={t('words.faq')}
                        routeFaq={Routes.FAQ}
                    />

                    <Dropdown
                        key={1}
                        dropName={users.user.fullname}
                        dropdownType="dropstart"
                        iconClassName='fa-regular  fa-user me-2'
                        buttonDropClassName='btn btn-secondary bg-black rounded-4'
                        styleButton={{}}
                        deleteProcess={() => { }}
                        action2={t('navigation.logOut')}
                        action3={t('navigation.inviteOwner')}
                        theRouteOfAction4=""
                        theRouteOfAction5=""
                        dataBsTarget="#inviteOwner"
                        routeFaq=""
                        updateMyCompany={loadcompany ? t('layout.header.updateCompany') : ""}
                        requestApproval={loadcompany ? t('layout.header.approvalRequests') : ""}
                        action6={""}
                        dataBsTargetAction6=""
                    />
                </div>

                <div className="modal" id='inviteOwner' tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content bg-dark ">
                            <div className="modal-header border-0">
                                <h5 className="modal-title text-white">{t('navigation.inviteOwner')}</h5>
                                <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {alert.isActive &&
                                    <div className={`alert alert-${alert.type}`} role="alert">
                                        {alert.message}
                                    </div>
                                }
                                <InputForm
                                    inputType="email"
                                    inputLabel="Email"
                                    inputName="email"
                                    inputValue={state.email}
                                    isFloatingLabel={true}
                                    labelClass="text-theme"
                                    onChange={(e) => handleChange(e)}
                                    inputContainerClass={'mb-3 p-0'}
                                    inputClass="bg-dark text-light text-bg-dark"
                                    inputStyle={{ height: 0, padding: 0 }}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                                <button type="button" onClick={handleSubmit} className="btn btn-primary">{t('words.invite')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id='changeCompany' tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content bg-dark ">
                            <div className="modal-header border-0">
                                <h5 className="modal-title text-white">{t('layout.header.updateCompany')}</h5>
                                <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {alert.isActive &&
                                    <div className={`alert alert-${alert.type}`} role="alert">
                                        {alert.message}
                                    </div>
                                }
                                {state.spinner ?
                                    <Spiner className="" />
                                    :
                                    <div className="d-flex flex-column justify-content-start">
                                        {state.myCompany.map((company: any, index: number) => (

                                            (state.isSubmit ?
                                                <Spiner className="" /> :
                                                <button type="button" onClick={() => switchToNewCompany(company._id)} className="text-primary text-decoration-none border-0 bg-dark fs-5 text-uppercase text-start">{company.name}</button>
                                            )
                                        ))

                                        }
                                    </div>

                                }

                            </div>
                            <div className="modal-footer">
                                <button ref={closeRef} type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <ApprovalRequestModal approvalList={listApproval} loading={loading} />
        </>
    )
}

export default HeaderLayout 