import { useState, useEffect } from 'react';
import { FamlaServices } from '../services';

import { useAppSelector } from './../stores/store';

const useSearch = (url: string) => {
    const token = useAppSelector((state) => state.auth.access_token);
    const [searchQuery, setSearchQuery] = useState<string | undefined>('');
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [searchLoad, setSearchLoad] = useState(false);

    useEffect(() => {
        const fetchSearchResults = async () => {
            if (searchQuery && token) {
                const requestData = { search: searchQuery };
                let res = await FamlaServices("api_host", url, 'POST', requestData, token);
                console.log('reponse searchResult', res.body.data);
                if (res.status === 201) { 
                    setSearchResults(res.body.data); 
                    setSearchLoad(true); 
                }
            } else { 
                setSearchResults([]); 
            }
        };
        fetchSearchResults();
    }, [token, searchQuery, url]);

    return { searchQuery, setSearchQuery, searchResults, searchLoad };
};
export default useSearch;
