import React from 'react'
import { useTranslation } from 'react-i18next'

import { AppLayout } from '../../layouts'
import { Images } from '../../constants'
import { Link } from 'react-router-dom'
import { Routes } from '../../routes'


const Privacy = () => {
    const { t } = useTranslation();
    return (
        <AppLayout>
            <div className="container-fluid fixed-top mb-3 bg-black d-flex w-100">
                <div className="text-white fs-5 mb-0  pt-2 " style={{ height: '50px' }}> <span className=""> <img className='img-fluid' src={Images.logov1} alt="" style={{ height: '30px' }} /></span></div>
            </div>
            <span className="mb-5 text-black">.</span>
            <div className="container align-items-center my-5">
                <div className="container align-items-center my-5">
                    <Link to={Routes.PROCESS_VIEW} className="text-light fw-bold fs-4 text-decoration-none text-uppercase">
                        <div className="d-flex">
                            <span><i className="fa-solid fa-arrow-left"></i></span>
                            <p className="ms-1">{t('navigation.backHome')}</p>
                        </div>
                    </Link>
                    <h6 className="fw-blod fs-4  pb-3 text-theme bg-black pt-0   text-uppercase">{t('views.legalDocs.privacy.privacyAndSecurityPolicy')}</h6>
                    <ol className="fs-6 text-white p-4">
                        <li className="text-white fs-4">{t('views.legalDocs.privacy.t1')}</li>
                        <p className="flex-wrap">{t('views.legalDocs.privacy.p1')}</p>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.privacy.t2')}</li>
                        <ul>
                            <li>{t('views.legalDocs.privacy.ulLi1')}</li>
                            <li>{t('views.legalDocs.privacy.ulLi2')}</li>
                            <li>{t('views.legalDocs.privacy.ulLi3')}</li>
                        </ul>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.privacy.t3')}</li>
                        <ul>
                            <li>{t('views.legalDocs.privacy.ulLi4')}</li>
                            <li>{t('views.legalDocs.privacy.ulLi5')}</li>
                            <li>{t('views.legalDocs.privacy.ulLi6')}</li>
                        </ul>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.privacy.t4')}</li>
                        <ul>
                            <li>{t('views.legalDocs.privacy.ulLi7')}</li>
                            <li>{t('views.legalDocs.privacy.ulLi8')}</li>
                            <li>{t('views.legalDocs.privacy.ulLi9')}</li>
                            <li>{t('views.legalDocs.privacy.ulLi10')}</li>
                        </ul>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.privacy.t5')}</li>
                        <ul>
                            <li>{t('views.legalDocs.privacy.ulLi11')}</li>
                            <li>{t('views.legalDocs.privacy.ulLi12')}</li>
                            <li>{t('views.legalDocs.privacy.ulLi13')}</li>
                            <li>{t('views.legalDocs.privacy.ulLi14')}</li>
                        </ul>
                        <li className="text-white mt-3 fs-4 "> {t('views.legalDocs.privacy.t6')}</li>
                        <p>{t('views.legalDocs.privacy.p2')} </p>
                        <li className="text-white mt-3 fs-4"> {t('views.legalDocs.privacy.t7')}</li>
                        <p>{t('views.legalDocs.privacy.p3')} </p>
                        <li className="text-white mt-3 fs-4">{t('navigation.contactUs')}</li>
                        <p>{t('views.legalDocs.privacy.p4')}:  <span className='text-theme'>contact@famla.ai</span></p>
                    </ol>
                </div>
            </div >
        </AppLayout >
    )
}
export default Privacy