export const simlane_export = [
    {
        "id": "main",
        "type": "$swimlane",
        "height": 227 * 4,
        "width": 2920,
        "header": {
            "closable": true,
            "text": "Process name"
        },
        "layout": [
            [1],
            [2],
            [3],
            [4],
        ],
        "subHeaderRows": {
            "headers": [
                {
                    "text": "Dep1"
                },
                {
                    "text": "Dep2"
                },
                {
                    "text": "Dep3"
                },
                {
                    "text": "Dep4"
                },
            ]
        }
    },
    {
        "id": 1,
        "type": "$sgroup",
        "groupChildren": [
            "1elg1",
        ]
    },
    {
        "id": 2,
        "type": "$sgroup",
        "groupChildren": [
            "1elg2",
            "2elg2",
            "3elg2",
            "4elg2",
        ]
    },
    {
        "id": 3,
        "type": "$sgroup",
        "groupChildren": [
            "1elg3",
            "2elg3",
        ]
    },
    {
        "id": 4,
        "type": "$sgroup",
        "groupChildren": [
            "1elg4",
            "2elg4",
            "2elg4",
        ]
    },
    {
        "type": "start",
        "text": "start",
        "x": 120,
        "y": 100,
        "id": "1elg1",
    },
    {
        "type": "process",
        "text": "process1",
        "x": 120,
        "y": 320,
        "id": "1elg2",
    },
    {
        "type": "decision",
        "text": "decision1",
        "x": 340,
        "y": 320,
        "id": "2elg2",
    },
    {
        "type": "process",
        "text": "process2",
        "x": 580,
        "y": 320,
        "id": "3elg2",
    },
    {
        "type": "process",
        "text": "process3",
        "x": 820,
        "y": 320,
        "id": "4elg2",
    },
    {
        "type": "process",
        "text": "process4",
        "x": 340,
        "y": 540,
        "id": "1elg3",
    },
    {
        "type": "decision",
        "text": "decision2",
        "x": 580,
        "y": 540,
        "id": "2elg3",
    },
    {
        "type": "process",
        "text": "process5",
        "x": 340,
        "y": 740,
        "id": "1elg4",
    },
    {
        "type": "process",
        "text": "process6",
        "x": 580,
        "y": 740,
        "id": "2elg4",
    },
    {
        "type": "end",
        "text": "end",
        "x": 840,
        "y": 740,
        "id": "3elg4",
    },
    {
        "type": "line",
        "stroke": "#2196F3",
        "connectType": "elbow",
        "from": "1elg1",
        "to": "1elg2",
        "fromSide": "bottom",
        "toSide": "top",
        "forwardArrow": "filled",
        "backArrow": "",
    },
    {
        "type": "line",
        "stroke": "#2196F3",
        "connectType": "elbow",
        "from": "1elg2",
        "to": "2elg2",
        "fromSide": "right",
        "toSide": "left",
        "forwardArrow": "filled",
        "backArrow": "",
    },
    {
        "type": "line",
        "stroke": "#2196F3",
        "connectType": "elbow",
        "from": "2elg2",
        "to": "3elg2",
        "fromSide": "right",
        "toSide": "left",
        "forwardArrow": "filled",
        "backArrow": "",
    },
    {
        "type": "line",
        "stroke": "#2196F3",
        "connectType": "elbow",
        "from": "2elg2",
        "to": "1elg3",
        "fromSide": "bottom",
        "toSide": "top",
        "forwardArrow": "filled",
        "backArrow": "",
    },
    {
        "type": "line",
        "stroke": "#2196F3",
        "connectType": "elbow",
        "from": "1elg3",
        "to": "1elg4",
        "fromSide": "bottom",
        "toSide": "top",
        "forwardArrow": "filled",
        "backArrow": "",
    },
    {
        "type": "line",
        "stroke": "#2196F3",
        "connectType": "elbow",
        "from": "1elg4",
        "to": "2elg3",
        "fromSide": "right",
        "toSide": "left",
        "forwardArrow": "filled",
        "backArrow": "",
    },
    {
        "type": "line",
        "stroke": "#2196F3",
        "connectType": "elbow",
        "from": "3elg2",
        "to": "2elg3",
        "fromSide": "bottom",
        "toSide": "top",
        "forwardArrow": "filled",
        "backArrow": "",
    },
    {
        "type": "line",
        "stroke": "#2196F3",
        "connectType": "elbow",
        "from": "2elg3",
        "to": "2elg4",
        "fromSide": "bottom",
        "toSide": "top",
        "forwardArrow": "filled",
        "backArrow": "",
    },
    {
        "type": "line",
        "stroke": "#2196F3",
        "connectType": "elbow",
        "from": "2elg3",
        "to": "4elg2",
        "fromSide": "right",
        "toSide": "left",
        "forwardArrow": "filled",
        "backArrow": "",

    },
    {
        "type": "line",
        "stroke": "#2196F3",
        "connectType": "elbow",
        "from": "2elg4",
        "to": "3elg4",
        "fromSide": "right",
        "toSide": "left",
        "forwardArrow": "filled",
        "backArrow": "",
    },
    {
        "type": "line",
        "stroke": "#2196F3",
        "connectType": "elbow",
        "from": "4elg2",
        "to": "3elg4",
        "fromSide": "bottom",
        "toSide": "top",
        "forwardArrow": "filled",
        "backArrow": "",
    },
];