// React Component
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface LinkPros {
    target?: "_blank" | "_parent" | "_self" | "_top" | undefined
    urlLink: string
}

const ViewAllLink = (props: LinkPros) => {
    const { target, urlLink, } = props
    const { t } = useTranslation();
    return (
        <Link to={urlLink} className="col-6 mt-3 text-decoration-none" target={target}>
            <div className="btn-btn-secondary bg-black border border-0 text-white d-flex flex-column align-items-center pt-4">
                <span className='mt-3 text-decoration-underline'>{t('views.processView.viewAll')}</span>
            </div>
        </Link>
    )
}

export default ViewAllLink