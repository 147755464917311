// React Component
import React from 'react'

interface ButtonProps {
    btnClass?: string
    btnStyle?: React.CSSProperties
    buttonType?: "submit" | "reset" | "button" | undefined
    iconName?: string
    isLoading: boolean
    labelClass?: string
    name: string
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    spinnerColor?: string
    styleProps?: React.CSSProperties
    withIcon?: boolean
    arrowVisible: boolean
    arrowColor: string
}

const Buttons = (props: ButtonProps) => {
    const { isLoading, buttonType, btnClass, iconName, labelClass, name, onClick, spinnerColor, styleProps, withIcon, arrowVisible, arrowColor } = props

    return (
        <button type={buttonType} className={`btn ${btnClass}`} style={styleProps} onClick={(e) => onClick && onClick(e)}>
            {isLoading ?
                <div className={`spinner-border fs-6 ${spinnerColor}`} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div> :
                <>
                    {withIcon ?
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <i className={`${iconName}`}></i>
                            <span className={`${labelClass}`}>{name}</span>
                            <span></span>
                        </div> :
                        <div>
                            <span className={`${labelClass}`}>{name}</span>
                            {arrowVisible && <i className={`fa-solid fa-arrow-right ms-2 ${arrowColor}`}></i>}
                        </div>
                    }
                </>
            }
        </button>

    )
}

export default Buttons