// React Compoment
import React from 'react';

// Features
import { ResetPassword } from '../../features/auth';
import { AuthLayout } from '../../layouts'

const ResetPasswordView = () => {
    return (
        <AuthLayout>
            <ResetPassword />
        </AuthLayout>
    )
}

export default ResetPasswordView;