// Reac Component
import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// App Component
import { FamlaServices } from '../../services'
import { Metrics } from '../../constants'
import { Buttons, InputForm } from '../../components'
import { Routes } from '../../routes'
import { AppLayout } from '../../layouts'

// Features
import { iAlert } from '../../interfaces'
// import { useAppDispatch } from '../../stores'

const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const dispatch = useAppDispatch();
    const [user, setUser] = React.useState({
        email: '',
        isSubmit: false,
        isValidMail: true
    });
    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (user.email.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.emailIsRequired') });
            return;
        }

        let formData = {
            email: user.email,
        }
        setUser({ ...user, isSubmit: true });
        let data = await FamlaServices("api_host", "/auth/request/new-password", "POST", formData, "");

        if (data.status === 201) {
            setUser({ email: '', isSubmit: false, isValidMail: true });
            navigate(Routes.AUTH_LOGIN);
        }
        else if (data.status === 404) {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.emailAddressIs') });
            setUser({ ...user, email: user.email, isSubmit: false });
        } else {
            setUser({ ...user, email: user.email, isSubmit: false });
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.anErrorOcured') });
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { type, name, value, checked } = event.target;
        let fieldValue: string | number | boolean;
        if (type === 'checkbox') {
            fieldValue = checked;
        } else if (type === 'number') {
            fieldValue = value !== '' ? Number(value) : '';
        } else {
            fieldValue = value !== undefined ? value : '';
        }
        fieldValue = typeof fieldValue !== 'string' ? String(fieldValue) : fieldValue;
        const trimmedValue: string = typeof fieldValue === 'string' ? fieldValue.trim() : '';
        let isEmailValid = name === 'email' ? Metrics.mailFormat.test(trimmedValue) : true;

        setUser({ ...user, [name]: fieldValue, isValidMail: isEmailValid });
    };

    return (
        <AppLayout>
            <div className="w-75 ms-5 custom-padding-top">
                <div className="mb-4">
                    <h1 className="mb-1 fw-bold text-white fs-3">{t('views.auth.resetYourPassword')}</h1>
                    <p className='text-white opacity-50'>{t('views.auth.msgReset')}</p>
                </div>
                <form onSubmit={handleSubmit}>
                    {alert.isActive &&
                        <div className={`alert alert-${alert.type}`} role="alert">
                            {alert.message}
                        </div>
                    }

                    <InputForm
                        inputType="email"
                        inputLabel={t('views.auth.emailAddres')}
                        inputName="email"
                        inputValue={user.email}
                        isFloatingLabel={true}
                        labelClass="text-theme"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                        inputContainerClass={user.isValidMail ? 'mb-3' : 'mb-0'}
                    />
                    <span className={`text-danger fs-6 d-bloc ${!user.isValidMail ? 'mb-3' : 'mb-0'}`}>{user.isValidMail ? '' : 'Enter a valid mail'}</span>

                    <Buttons
                        name={t('views.auth.reset')}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => { handleSubmit(event) }}
                        isLoading={user.isSubmit}
                        btnClass="btn-theme w-100"
                        arrowVisible={false}
                        labelClass="text-white fw-bold"
                        styleProps={{ height: 45 }}
                        arrowColor=''
                    />

                    <p className="mt-2 text-white opacity-50 ">
                        {t('views.auth.dontHaveAccount')} <Link to={Routes.AUTH_SIGNIN} className="text-decoration-none text-warning">{t('views.auth.signUp')}</Link>
                    </p>
                </form>
            </div>
        </AppLayout>
    )
}

export default ResetPassword;