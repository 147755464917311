import React from 'react';
import { TextAreaForm } from "../../components"
import { TypingParagraph } from '../../utils';
import { TFunction } from 'i18next';


interface AreaProps {
    state: {
        autorisation: boolean;
        answer: string;
        interviewId: string;
    };
    handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    sendAnswer: (e: React.MouseEvent<HTMLButtonElement>, interviewwId: string | undefined, answer: string, idLastAnwser: string) => void;
    interviewwId: string | undefined;
    idLastAnwser: string;
    tag: () => boolean;
    t: TFunction;
    spinner: boolean;
    isInputBlocked: boolean;
    swimlanes: any[];
    role: string;
    time: Number
}

const Area = (props: AreaProps) => {
    const {
        state,
        handleChange,
        sendAnswer,
        interviewwId,
        idLastAnwser,
        tag,
        t,
        spinner,
        isInputBlocked,
        swimlanes,
        role,
        time,
    } = props

    const messages: string[] = [
        t('views.processInterview.s1', { time }),
        t('views.processInterview.s2', { time }),
        t('views.processInterview.s3', { time }),
    ];

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-row" style={{ marginBottom: '100px' }}>
                {state.autorisation && tag() && swimlanes.length === 0 && (
                    <div className="w-100">
                        <TextAreaForm
                            name={"area"}
                            sendButton={state.answer.trim().length > 0}
                            label=""
                            rows={1}
                            classNameArea="form-control w-100 bg-dark text-light text-bg-dark py-2 rounded-4 pe-5 border border-info"
                            classNameContainer="w-100"
                            placeholder={`${t('views.processInterview.yourResponse')}...`}
                            onChange={handleChange}
                            value={state.answer}
                            interviewId={state.interviewId}
                            sendAnswer={(e) => sendAnswer(e, interviewwId, state.answer, idLastAnwser)}
                            spinner={spinner}
                            maxRows={2}
                            minRows={1}
                            button={true}
                            readOnly={isInputBlocked}
                        />
                    </div>
                )}
                {swimlanes.length === 1 && role === "ADMIN" && (
                    <div className="text-light opacity-50 w-100 mt-3">
                        <TypingParagraph
                            messages={messages}
                            delayBetweenMessages={8000}
                            typingSpeed={50}
                            className="fs-6 w-100"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Area;
