import { RouterProvider } from "react-router-dom"

import { NavigationApp } from './routes'

function App() {
    return (
        <RouterProvider router={NavigationApp} />
    )
}


export default App
