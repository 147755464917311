// React Compoment
import React from 'react';

// Features
import { ResetLastPassword } from '../../features/auth';
import { AuthLayout } from '../../layouts'

const ResetLastPasswordView = () => {
    return (
        <AuthLayout>
            <ResetLastPassword />
        </AuthLayout>
    )
}

export default ResetLastPasswordView;