import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18n } from '../../services'


const ChangeLanguageModal = () => {
    const { t } = useTranslation()
    const [currentLanguage, setCurrentLanguage] = React.useState(() => {
        try {
            return localStorage.getItem('currentLanguage') || navigator.language;
        } catch (error) {
            return navigator.language;
        }
    });

    React.useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language: string) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);
        // let action = {
        //     type: "LANGUAGE",
        //     value: {
        //         language: updateLanguage,
        //         languageName: updateLanguage === 'fr' ? t('words.french') : t('words.english')
        //     }
        // };
        // dispatch(action);

        // updateLangByusers(updateLanguage);
        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };

    return (
        <div className="modal fade" id="changeLanguageModal" tabIndex={-1} aria-labelledby="ChangeLanguageModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className={`modal-content border-0 p-4 bg-dark `}>
                    <div className={`d-flex justify-content-between text-white`}>
                        <h6 className={``} id="exampleModalLabel1">{t('words.languages')}</h6>
                        <button type="button" className={`bg-transparent border-0`} data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark text-white"></i></button>
                    </div>
                    <form>
                        <div className="modal-body pt-0 px-0" >
                            <div className="row py-3 gy-3 m-0">
                                <div className="langauge-item col-6 px-1 mt-2 text-white " onClick={() => changeLanguage('en')} data-bs-dismiss="modal" aria-label="Close">
                                    <input type="radio" className="btn-check" name="options-outlined" id="english2" defaultChecked={true} />
                                    <label className={`btn btn-language btn-sm px-2 py-2 rounded-2 d-flex align-items-center justify-content-between text-white ${currentLanguage === "en" ? "bg-theme border-0" : "border-theme"} `} htmlFor="english2">
                                        <span className={`text-start d-grid `}>
                                            <small className="ln-18">English</small>
                                            <small className="ln-18">Anglais</small>
                                        </span>
                                        <i className="fa-regular fa-circle-check text-white"></i>
                                    </label>
                                </div>
                                <div className="langauge-item col-6 px-1 mt-2 text-white" onClick={() => changeLanguage('fr')} data-bs-dismiss="modal" aria-label="Close">
                                    <input type="radio" className="btn-check" name="options-outlined" id="punjabi51f" defaultChecked={currentLanguage === 'fr' ? true : false} />
                                    <label className={`btn btn-language btn-sm px-2 py-2 rounded-2 d-flex align-items-center justify-content-between text-white border border-primary ${currentLanguage === "fr" ? "bg-theme border-0" : "border-theme"} `} htmlFor="punjabi51f">
                                        <span className={`text-start d-grid`}>
                                            <small className="ln-18">French</small>
                                            <small className="ln-18">Français</small>
                                        </span>
                                        <i className="fa-regular fa-circle-check text-white"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export default ChangeLanguageModal;