import React from 'react';

// Définition des types pour les props
interface ButtonProps {
    text: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    dataBsTarget?: string;
    dataBsToggle?: string;
    icon?: JSX.Element;
    isSelected?: boolean;
}

interface ButtonGroupProps {
    buttons: ButtonProps[];
    className?: string;
    role?: string;
    ariaLabel?: string;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ buttons, className = '', role = 'group', ariaLabel = '' }) => {
    return (
        <div className={`btn-group ${className}`} role={role} aria-label={ariaLabel}>
            {buttons.map((button, index) => (
                <button
                    key={index}
                    className={`btn btn-outline-theme btn-sm border border-theme text-white px-3 ${button.className} ${button.isSelected ? 'bg-theme' : ''}`}
                    onClick={button.onClick}
                    data-bs-target={button.dataBsTarget}
                    data-bs-toggle={button.dataBsToggle}
                >
                    {button.icon ? button.icon : button.text}
                </button>
            ))}
        </div>
    );
};

export default ButtonGroup;
