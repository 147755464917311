import React from 'react';
import { TypingParagraphe } from '../../utils';
import { Images } from '../../constants';

// Définition des types pour les props
interface QuestionProps {
    index: number;
    currentIndex: number;
    questionText: string;
    questionAndResponseLength: number;
    setCurrentIndex: (index: number) => void;
}

const Question: React.FC<QuestionProps> = ({
    index,
    currentIndex,
    questionText,
    questionAndResponseLength,
    setCurrentIndex,
}) => {
    return (
        <>
            <img src={Images.logov1} height={20} alt="Logo" />
            <p className="fs-6 ps-4 text-light fw-lighter mt-1">
                {index === questionAndResponseLength - 1 ? (
                    <TypingParagraphe
                        text={questionText}
                        time={15}
                        className="fs-6"
                        onIndexChange={setCurrentIndex}
                    />
                ) : (
                    <span>{questionText}</span>
                )}
            </p>
        </>
    );
};

export default Question;
