const APP_ENV = "test"
const APP_HOST = "test"

const API_LIVE_HOST = "http://127.0.0.1:8000"
const API_TEST_HOST = "https://fl-services.famla.ai:9540/api/v1"
//const API_TEST_HOST = "http://13.48.204.37:9540/api/v1"

export const env = {
    "app_host": APP_HOST,
    "api_host": APP_ENV === "test" ? API_TEST_HOST : API_LIVE_HOST,
    "signin_with_apple": "http://appleid.com",
    "signin_with_google": "http://google.com",
    "signin_with_microsoft": "http://microsoft.com",
}
