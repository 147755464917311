// React Component
import React from 'react'

// App Component
import ProcessView from './ProcessView'


const DashboardView = () => {
    return (
        <ProcessView />
    )
}

export default DashboardView