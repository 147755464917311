//React
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

//component
import { useAppSelector } from "../../stores"
import { FamlaServices } from '../../services'
import { InputForm, Spiner } from '../../components'


//interface
import { iAlert } from '../../interfaces'



interface ChildProps {
    processId: string
    processName: string
    description?: string
    setProject: React.Dispatch<React.SetStateAction<any[]>>;
    swimlaneId?: string
}


const EditModal = (props: ChildProps) => {
    const { processId, processName, description, setProject, swimlaneId } = props;
    const { t } = useTranslation();
    const closeModal = useRef<HTMLButtonElement>(null);
    const users = useAppSelector(state => state.auth)
    const [state, setState] = React.useState({
        processName: processName,
        isSubmit: false,
        role: "",
        description: description
    })

    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })



    const handleChange = (event: any) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'name') {
            setState((prev) => ({ ...prev, processName: fieldValue }))
        } else if (name === "description") {
            setState((prev) => ({ ...prev, description: fieldValue }))
        }
    };


    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (state.processName.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: 'Enter a process name' });
            return;
        }


        setState({ ...state, isSubmit: true });

        let form = {
            name: state.processName,
            updated_at: Date.now()
        };

        let data = await FamlaServices("api_host", `/mapping/update/${swimlaneId}`, "PUT", form, users.access_token);

        if (data.status === 200) {
            setAlert({ isActive: true, type: 'success', message: t("navigation.successfullyUpdated") });
            setState((prevState) => ({ ...prevState, isSubmit: false, role: "", email: "" }));
            let datas = await FamlaServices("api_host", `/mapping/process/${processId}`, "GET", "", users.access_token);
            if (datas.status === 200) {
                setProject(datas.body.data)
                setAlert({ isActive: false, type: '', message: "" });
            }
            if (closeModal.current) {
                closeModal.current.click();
            }
        }

        else if (data.status === 404) {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOccurredMappNotFound") });
        }
        else {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcured") });
        }
        setState({ ...state, isSubmit: false });
    };

    return (
        <div className="modal" id={`edit${swimlaneId}`} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark ">
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-white">{t('words.edit')}</h5>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {alert.isActive &&
                            <div className={`alert alert-${alert.type}`} role="alert">
                                {alert.message}
                            </div>
                        }
                        <InputForm
                            inputType="text"
                            inputLabel={t('navigation.diagramName')}
                            inputName="name"
                            inputValue={state.processName || processName}
                            isFloatingLabel={true}
                            labelClass="text-theme"
                            onChange={(e) => handleChange(e)}
                            inputContainerClass={'mb-3 p-0'}
                            inputClass="bg-dark text-light text-bg-dark"
                            inputStyle={{ height: 0, padding: 0 }}
                        />
                        {/* <InputForm
                            inputType="text"
                            inputLabel="description"
                            inputName="description"
                            inputValue={state.description || description}
                            isFloatingLabel={true}
                            labelClass="text-theme"
                            onChange={(e) => handleChange(e)}
                            inputContainerClass={'mb-3 p-0'}
                            inputClass="bg-dark text-light text-bg-dark"
                            inputStyle={{ height: 0, padding: 0 }}
                        /> */}

                    </div>
                    <div className="modal-footer">
                        <button ref={closeModal} type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                        <button type="button" onClick={(e) => handleSubmit(e)} className="btn btn-primary">{state.isSubmit ? <Spiner className='spinner-border-sm' /> : <>{t('words.edit')}</>}</button>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default EditModal