// React Compoment
import React from 'react';

// Features
import { UsersLogin } from '../../features/auth';
import { AuthLayout } from '../../layouts'

const LoginView = () => {
    return (
        <AuthLayout>
            <UsersLogin />
        </AuthLayout>
    )
}

export default LoginView;