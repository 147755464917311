import React from 'react';


interface TypingParagraphProps {
    text: string;
    time: number;
    className: string;
    onIndexChange: (currentIndex: number) => void;
}

const TypingParagraphe: React.FC<TypingParagraphProps> = ({ text, time, className, onIndexChange }) => {
    const [displayText, setDisplayText] = React.useState<string>('');

    React.useEffect(() => {
        let currentIndex = 0;
        const interval = setInterval(() => {
            if (currentIndex <= text.length) {
                setDisplayText(text.slice(0, currentIndex));
                currentIndex++;
                onIndexChange(currentIndex);
            } else {
                clearInterval(interval);
            }
        }, time);

        return () => clearInterval(interval);
    }, [text, time, onIndexChange]);

    return <p className={className}>{displayText}</p>;
};


interface MessageCarouselProps {
    messages: string[];
    delayBetweenMessages: number;
    typingSpeed: number;
    className: string;
}

const TypingParagraph: React.FC<MessageCarouselProps> = ({ messages, delayBetweenMessages, typingSpeed, className }) => {
    const [currentMessageIndex, setCurrentMessageIndex] = React.useState<number>(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, delayBetweenMessages);

        return () => clearInterval(interval);
    }, [messages.length, delayBetweenMessages]);

    return (
        <TypingParagraphe
            text={messages[currentMessageIndex]}
            time={typingSpeed}
            className={className}
            onIndexChange={() => { }}
        />
    );
};


export { TypingParagraphe, TypingParagraph };
