// React Component
import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// App Component
import { AppLayout, HeaderLayout, ProcessLayout } from '../../layouts'
import { FamlaServices } from '../../services'
import { useAppSelector } from '../../stores'
import { ProcessList } from '../../components'


// interface
import { iProject } from '../../interfaces'
interface StateType {
    isLoadingProcess: boolean;
    process: any[];
    project: iProject;
    isLoadingProject: boolean
}


const ProcessByFolderView = () => {
    const { id } = useParams();
    const users = useAppSelector((state) => state.auth);
    const { t } = useTranslation();
    const [state, setState] = React.useState<StateType>({
        isLoadingProcess: true,
        isLoadingProject: true,
        process: [],
        project: {} as iProject,
    })


    React.useEffect(() => {

        const loadProcessByFolder = async () => {

            try {
                let data = await FamlaServices("api_host", `/process/project/${id}`, "GET", "", users.access_token);
                if (data.status === 200) {
                    setState((prev) => ({ ...prev, process: data.body.data, isLoadingProcess: false }));
                } else {
                    setState((prev) => ({ ...prev, isLoadingProcess: false }));
                }
            } catch (error: any) {
                setState((prev) => ({ ...prev, isLoadingProcess: false }));
            }
        }

        loadProcessByFolder();

        const loadProjectById = async () => {

            try {
                let data = await FamlaServices("api_host", `/project/by/id/${id}`, "GET", "", users.access_token);
                if (data.status === 200) {
                    const projects = data.body.data
                    setState((prev) => ({ ...prev, project: projects, isLoadingProject: false }));
                }
            } catch (error: any) {
                setState((prev) => ({ ...prev, error: error.message }));
            }
        }

        loadProjectById();

    }, [id, users.access_token])


    return (
        <AppLayout>
            <div className="container-fluid mt-3 pb-3 px-5 w-100">
                <HeaderLayout loadcompany={false} />

                <ProcessLayout
                    title={state.project.title && state.project.title}
                    arrowBack={state.project.title ? true : false}
                    style={{ height: 'auto', width: "100%" }}
                    addClassName='overflow-auto w-100 controleScroll'
                >
                    <ProcessList isLoading={state.isLoadingProcess} list={state.process} t={t} />
                </ProcessLayout>

            </div>
        </AppLayout>
    )
}

export default ProcessByFolderView