const Routes = {
    HOME: '/',
    AUTH: '/',
    AUTH_LOGIN: '/auth/login',
    AUTH_SIGNIN: '/auth/signin',
    AUTH_MAIL: '/auth/mail',
    AUTH_RESET_PASSWORD: '/auth/reset-password',
    DASHBOARD: '/dashboard',
    CREATE_GROUP_PROCESS: "/process/createGroup",
    CREATE_MY_COMPANY: "/company/create",
    CONTACTUS: '/contact',
    PROCESS_CREATE: '/process/create',
    PROCESS_BY_FOLDER: '/process/folder/',
    PROCESS_INTERVIEW: '/process/interviews/',
    PROCESS_VIEW: '/process/overview',
    MY_PROCESS: '/process/my/processes',
    ALL_GROUP: '/process/group',
    ALL_MAP: '/process/all',
    MAPPING: '/process/mapping/',
    PRIVACY: '/Privacy Policy',
    FAQ: '/faq',
}

export default Routes