import React from "react";
import { TFunction } from 'i18next';

//APP
import { Button } from "../features/swimLane";
import Spiner from "./Spiner";
import { TypingParagraph } from "../utils";


interface ChildProps {
    refresh: () => void;
    error: boolean
    t: TFunction
    time?: number
}

const SwimlaneError: React.FC<ChildProps> = (props) => {
    const { error, refresh, t, time } = props;

    const messages: string[] = [
        t('features.swimlane.s1', { time }),
        t('features.swimlane.s2', { time }),
        t('features.swimlane.s3', { time }),
    ];


    return (
        <div className="vh-100">
            {error ?
                <div className="card text-center rounded-0 vh-100">
                    <div className="card-body">
                        <span>
                            <i className="fa-solid fa-triangle-exclamation fs-1 text-black mb-5"></i>
                        </span>
                        <h5 className="card-title"> {t('features.swimlane.oops')}</h5>
                        <p className="card-text px-5">
                            {t('features.swimlane.WeSorry')}
                        </p>
                        <div className="d-flex justify-content-center">
                            <Button className="dhx_sample-btn dhx_sample-btn--flat border-0 bg-dark " name={t('words.refresh')} onClick={refresh} submit={false} />
                        </div>
                    </div>
                </div> :

                <div className="mt-5 p-2">
                    <div className=" d-flex justify-content-center align-items-center">

                        <span className="text-white text-start pb-2 fs-5">
                            {t('features.swimlane.pleaseWait')}
                        </span>

                    </div>
                    {time !== 0 &&
                        <div className=" d-flex justify-content-center align-items-center text-white">
                            <TypingParagraph key={0} messages={messages} delayBetweenMessages={7000} typingSpeed={50} className='fs-6' />
                        </div>
                    }

                    <div className="d-flex justify-content-center">
                        <Spiner className="mt-5" />
                    </div>
                </div>

            }

        </div>

    );
}

export default SwimlaneError;
