// Reac Component
import React from 'react'
import { TFunction } from 'i18next';


// App Component
import { Buttons } from '../../components'
//import { env } from '../../services/app.conf'
import { FamlaServices } from '../../services'


interface iprops {
    t: TFunction;
}
const ButtonSocialeNetwork = (props: iprops) => {
    const { t } = props
    const [spinner, setSpinner] = React.useState(false);

    const navigateToNetwork = async (network: string) => {
        //let url = ""

        if (network === "apple") {
            // url = env['signin_with_apple']
        }
        else if (network === "google") {
            setSpinner(true)
            const data = await FamlaServices("api_host", "/google/login", "GET", "", "")
            if (data.status === 200) {
                let url = data.body.data
                window.location.assign(url);
            }
            //url = env['signin_with_google']
        }
        else {
            // url = env['signin_with_microsoft']
        }
        // window.location.href = url;
    }

    return (
        <div>
            <div className="d-flex flex-row justify-content-around align-items-center w-100">
                <hr className="border border-2" style={{ width: "40%" }} />
                <span className="fw-bold text-light">{t('words.or')}</span>
                <hr className="border border-2" style={{ width: "40%" }} />
            </div>

            <Buttons
                name={t('views.auth.continueWithGoogle')}
                onClick={() => navigateToNetwork('google')}
                isLoading={spinner}
                btnClass="btn-light w-100 mt-3"
                labelClass=""
                styleProps={{ height: 45 }}
                withIcon={true}
                iconName="fa-brands fa-google"
                arrowVisible={false}
                arrowColor=''
                spinnerColor='text-warning'
            />
            {/* <Buttons
                name={t('views.auth.continueWithMicrosoft')}
                onClick={() => navigateToNetwork('microsoft')}
                isLoading={false}
                btnClass="btn-light w-100 mt-2"
                labelClass=""
                styleProps={{ height: 45 }}
                withIcon={true}
                iconName="fa-brands fa-microsoft"
                arrowVisible={false}
                arrowColor=''
            /> */}
            {/* <Buttons
                name={t('views.auth.continueWithApple')}
                onClick={() => navigateToNetwork('apple')}
                isLoading={false}
                btnClass="btn-light w-100 mt-2"
                labelClass=""
                styleProps={{ height: 45 }}
                withIcon={true}
                iconName="fa-brands fa-apple"
                arrowVisible={false}
                arrowColor=''
            /> */}
        </div>
    )
}

export default ButtonSocialeNetwork;