//React
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

//component
import { useAppSelector } from "../../stores"
import { FamlaServices } from '../../services'


//interface

import { iAlert } from '../../interfaces'
import { Spiner } from '../../components'


interface ChildProps {
    processId: string

}


const PublishModal = (props: ChildProps) => {
    const { processId } = props
    const closeRef = useRef<HTMLButtonElement>(null);
    const users = useAppSelector(state => state.auth)
    const [state, setState] = React.useState({
        isSubmit: false,

    })

    const { t } = useTranslation();
    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setState({ ...state, isSubmit: true });

        let data = await FamlaServices("api_host", `/process/publish/process/${processId}`, "PUT", "", users.access_token);

        if (data.status === 200) {
            setAlert({ isActive: true, type: 'success', message: t('navigation.theProcessHasBeenHalted') });
            setState((prevState) => ({ ...prevState, isSubmit: false }));
            if (closeRef.current) {
                closeRef.current.click()
            }
        }

        else if (data.status === 400) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.youAreNotAuthorizedToPerformThisAction') });

        } else if (data.status === 404) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.processNotFound') });

        }
        else if (data.status === 401) {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.youAreNotLoggedIn") });

        }
        else {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcured") });
        }
        setState({ ...state, isSubmit: false });
    };

    return (
        <div className="modal" data-bs-backdrop="static" id={`publish${processId}`} tabIndex={-1} >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark ">
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-white">{t('navigation.markAsFinal')}</h5>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {alert.isActive &&
                            <div className={`alert alert-${alert.type}`} role="alert">
                                {alert.message}
                            </div>
                        }
                        <p className='text-theme'>{t('navigation.publishQuestion')}</p>
                    </div>
                    <div className="modal-footer">
                        <button ref={closeRef} type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                        <button type="button" onClick={handleSubmit} className="btn btn-primary">{state.isSubmit ? <Spiner className='spinner-border-sm' /> : <>{t('words.publish')}</>}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PublishModal 