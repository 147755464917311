import React from 'react';
import { NavLink } from 'react-router-dom';
import Spiner from '../Spiner';
import Dropdown from '../Dropdown';
import { Routes } from '../../routes';


interface DiagramListProps {
    loadDiagram: boolean;
    swimlanes: any[];
    role: string;
    processId: string | undefined;
    deleteSwimlaneDiagram: (id: string) => void;
    renameSwimlane: (id: string, name: string) => void;
    shareSwimlane: (id: string, action: string) => void;
    setModalWithSwimlaneId: (id: string, action: string) => void;
    slice: (text: string, maxLength: number) => string;
    t: (key: string) => string;
}

const DiagrammList: React.FC<DiagramListProps> = ({
    loadDiagram,
    swimlanes,
    role,
    processId,
    deleteSwimlaneDiagram,
    renameSwimlane,
    shareSwimlane,
    setModalWithSwimlaneId,
    slice,
    t
}) => {

    return (
        <div className="mt-3">
            <h4 className="text-light fs-6 mb-3 px-2">{t('views.processInterview.diagramsList')}</h4>
            <div className="overflow-auto d-flex flex-column customHeightDiagram">
                {loadDiagram ?
                    <Spiner className="spinner-border-sm" />
                    :
                    <>
                        {swimlanes.length === 0 ?
                            <div className="text-white ms-5">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            :
                            <>
                                {swimlanes.map((swimLane: any) => (
                                    <>
                                        {swimLane.is_finish ?
                                            <div key={swimLane._id} className="btn btn-outline-secondary rounded-4 border-0 px-2 diagram-item">
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex">
                                                        <span><i className="fa-solid fa-diagram-project text-white small"></i></span>
                                                        <NavLink to={`${Routes.MAPPING}${processId}/${swimLane._id}`} className={`text-light fs-6 text-decoration-none border-0`}>
                                                            <span className="ps-2">{slice(swimLane.name || swimLane._id, 23)}</span>
                                                        </NavLink>
                                                    </div>
                                                    {role !== "WORKER" &&
                                                        <div className="dropdown-container">
                                                            <Dropdown
                                                                dropName=''
                                                                dropdownType='btn-group dropend'
                                                                iconClassName='fa fa-ellipsis-vertical text-white small'
                                                                buttonDropClassName='bg-transparent border-0 ms-2 small'
                                                                styleButton={{}}
                                                                deleteProcess={() => deleteSwimlaneDiagram(swimLane._id)}
                                                                action1={t('words.delete')}
                                                                theRouteOfAction4=''
                                                                theRouteOfAction5=''
                                                                routeFaq=""
                                                                aproval={t('words.requestApproval')}
                                                                rename={t('words.rename')}
                                                                share={t('words.share')}
                                                                invitedAproval={() => setModalWithSwimlaneId(swimLane._id, 'aproval')}
                                                                renameSwimlane={() => renameSwimlane(swimLane._id, swimLane.name || '')}
                                                                shareSwimlane={() => shareSwimlane(swimLane._id, 'share')}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className="border-0 diagram-item px-2 text-light opacity-50">
                                                {t('views.processInterview.mapReady')}...
                                            </div>
                                        }
                                    </>

                                ))}

                            </>}
                    </>
                }
            </div>
        </div>
    );
};

export default DiagrammList;
