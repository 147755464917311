const Metrics = {
    borderWidth: 0.4,

    borderRadius: 8,
    buttonRadius: 8,

    mailFormat: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    DateRegex: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/,
    phoneRegex: /^[0-9]{4,}$/,

    background1: '#3A7CE6',
    background2: '#E04C60',
    background3: 'linear-gradient(to bottom, #6B20A5, #E04C60)',
    background4: 'linear-gradient(to bottom, #FEBC00, #C800A5)',
    background5: '#6BCEBB',
    background6: 'linear-gradient(to right top, #C800A5, #FF53A0, #C800A5, #FF53A0)',
}

export default Metrics
