
const avatar = require('../assets/img/avatar.jpg')
const bg_1 = require('../assets/img/bg_1.jpg')
const bg_2 = require('../assets/img/bg_2.jpg')
const logov1 = require('../assets/img/famla.png')
const mail = require('../assets/img/send-mail.png')
const famla = require('../assets/img/famla.png')
const l1 = require('../assets/img/l1.png')
const l2 = require('../assets/img/l2.png')
const Images = {
    avatar,
    bg_1,
    bg_2,
    famla,
    logov1,
    mail,
    l1,
    l2
};
export default Images