// React Component
import React, { CSSProperties } from 'react'
import { NavLink } from 'react-router-dom'

// App Component
import { Routes } from '../routes'
//interface
interface ChildProps {
    children: React.ReactNode
    title: string
    arrowBack: boolean
    style: CSSProperties
    addClassName: string

}

const ProcessLayout = (props: ChildProps) => {
    const { children, title, arrowBack, style, addClassName } = props


    return (
        <div className="position-relative ">
            <div className='overflow-hidden mt-3'>
                <div>
                    <div className='d-flex text-white fs-3'>
                        {arrowBack && <NavLink to={Routes.PROCESS_VIEW} className='me-2 text-decoration-none text-white'><i className="fa-solid fa-arrow-left"></i></NavLink>}
                        <h5 className='fs-3 pt-1'>{title}</h5>
                    </div>
                    <div className={`mt-0 ${addClassName}`} style={style}>
                        {children}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ProcessLayout