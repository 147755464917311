// React Component
import React from 'react'

// App Component
import { AppLayout, HeaderLayout } from '../../layouts'

// Features
import { GroupOfProcessCreate } from '../../features/process/process'

const NewGroupOfProcessView = () => {
    return (
        <AppLayout>
            <div className="px-3 mt-2 h-100 ">
                <HeaderLayout loadcompany={false} />
                <div className='container' style={{ marginTop: "10%" }}>
                    <GroupOfProcessCreate />
                </div>

            </div>
        </AppLayout>
    )
}

export default NewGroupOfProcessView