// React Compoment
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

// My Compoment
import App from './App'
import reportWebVitals from './reportWebVitals'
import './assets/css/App.css'

// Store
import { persistor, store } from './stores'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>,
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
