// Reac Component
import React from 'react'
import { Link, useSearchParams, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'

// App Component
import { FamlaServices } from '../../services'
import { Buttons, InputForm } from '../../components'
import { Routes } from '../../routes'
import { AppLayout } from '../../layouts'

// Features
import { iAlert } from '../../interfaces'
// import { useAppDispatch } from '../../stores'

interface iState {
    newPassword: string,
    password: string,
    isSubmit: boolean,
    isValidMail: boolean,
    verifyCode: string | null
}

const ResetLastPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [user, setUser] = React.useState<iState>({
        newPassword: '',
        password: '',
        isSubmit: false,
        isValidMail: true,
        verifyCode: ""
    });

    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })
    let [searchParams, setSearchParams] = useSearchParams()

    React.useEffect(() => {
        if (searchParams.get("pwd") != null) {
            setUser({ ...user, verifyCode: searchParams.get("pwd") });

            setSearchParams({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.isSubmit])



    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (user.newPassword.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.newPassWordIsRequired') });
            return;
        }

        let formData = {
            verify_code: user.verifyCode,
            passwordConfirm: user.newPassword,
            password: user.password,
        }
        setUser({ ...user, isSubmit: true });
        let data = await FamlaServices("api_host", "/auth/reset-password", "POST", formData, "");

        if (data.status === 201) {
            setUser((prev) => ({ ...prev, newPassword: '', password: '', isSubmit: false, isValidMail: true }));
            setAlert({ isActive: true, type: 'success', message: t('views.auth.passwordSuccessfully') });
            navigate(Routes.AUTH)
        }
        else if (data.body.status_code === 400 && data.body.description === "user does not exist") {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.userDoesNotExist') });
            setUser({ ...user, newPassword: user.newPassword, password: '', isSubmit: false });
        } else if (data.body.status_code === 400) {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.passwordsNotMatch') });
            setUser({ ...user, newPassword: user.newPassword, password: '', isSubmit: false });
        }
        else {
            setUser({ ...user, newPassword: user.newPassword, password: '', isSubmit: false });
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.anErrorOcured') });
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { type, name, value, checked } = event.target;
        let fieldValue: any;
        if (type === 'checkbox') {
            fieldValue = checked;
        } else {
            fieldValue = value;
        }
        if (name === "password") {
            setUser({ ...user, password: fieldValue })

        } else if (name === "newPassword") {
            setUser({ ...user, newPassword: fieldValue })
        }


    };


    return (
        <AppLayout>
            <div className="w-75 ms-5 custom-padding-top">
                <div className="mb-4">
                    <h1 className="mb-1 fw-bold text-white fs-3">{t('views.auth.resetYourPassword')}</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    {alert.isActive &&
                        <div className={`alert alert-${alert.type}`} role="alert">
                            {alert.message}
                        </div>
                    }

                    <InputForm
                        inputType="password"
                        inputLabel={t('views.auth.password')}
                        inputName="password"
                        inputValue={user.password}
                        isFloatingLabel={true}
                        labelClass="text-theme"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                        inputContainerClass={'mb-3'}
                    />
                    <InputForm
                        inputType="password"
                        inputLabel={t('views.auth.newPassword')}
                        inputName="newPassword"
                        inputValue={user.newPassword}
                        isFloatingLabel={true}
                        labelClass="text-theme"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                        inputContainerClass={'mb-3'}
                    />
                    <Buttons
                        name={t('views.auth.resetYourPassword')}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => { handleSubmit(event) }}
                        isLoading={user.isSubmit}
                        btnClass="btn-theme w-100"
                        arrowVisible={false}
                        labelClass="text-white fw-bold"
                        styleProps={{ height: 45 }}
                        arrowColor=''
                    />

                    <p className="mt-2 ">
                        <span className='text-white'>{t('views.auth.dontHaveAccount')} </span><Link to={Routes.AUTH_LOGIN} className="text-decoration-none text-warning">{t('views.auth.logIn')}</Link>
                    </p>
                </form>
            </div>
        </AppLayout>
    )
}

export default ResetLastPassword;