const fr = {
    form: {
        nameRequired: "Le nom est obligatoire",
        createProcess: {
            industryRequired: "L'industrie est nécessaire",
            invalidSelection: "Sélection de l'industrie invalide",
            processOverview: "Vue du processus",
            processNameAlreadyExist: "Le nom du processus existe déjà",
            processCannotCreate: "le processus ne peut pas être créé par son utilisateur",
            selectIndustry: "Sélectionner un secteur d'activité",
            selectLanguage: "Sélectionner une langue d'interview",
        },
        createGroup: {
            createGroup: "Créer un dossier",
            folderNameExist: "Le nom du dossier existe déjà",
            groupCreateSuccessfully: "Création du dossier réussie",
            selectOwner: "Sélectionner un propriétaire",
            youNotAuthorized: "Vous n'êtes pas autorisé à créer un dossier"
        },
        createCompany: {
            createACompany: "Créer une entreprise",
            companyName: "Nom de l'entreprise",
            postalCode: "Code postal ",
            phoneNumber: "N° de téléphone",
            website: "Site web"
        }
    },

    features: {
        swimlane: {
            approveQuestion: "Êtes-vous sûr de vouloir approuver ce diagramme ?",
            disApproveQuestion: "Êtes-vous sûr de vouloir désapprouver ce diagramme ?",
            exportPDF: "Exporter en PDF",
            exportPNG: "Exporter en PNG",
            oops: "Oups ! Quelque chose n'a pas fonctionné",
            pleaseWait: "Veuillez patienter, votre map est en cours de préparation.",
            // eslint-disable-next-line no-template-curly-in-string
            s1: "Responsable de processus, vous avez économisé {{time}} précieuses minutes d'entretiens grâce à Famla !",
            // eslint-disable-next-line no-template-curly-in-string
            s2: "{{time}} minutes d'entretien économisées ! Famla accompagne les responsables de processus comme vous.",
            // eslint-disable-next-line no-template-curly-in-string
            s3: "{{time}} minutes d'entretien en moins. Responsable de processus, Famla simplifie votre travail.",
            sucessApprov: "Succès ! Le diagramme a été approuvé avec succès.",
            sucessDisapprov: "Succès ! Le diagramme a été rejeté avec succès.",
            versionHistory: "Historique des versions",
            WeSorry: "Nous sommes désolés, mais votre diagramme de couloir de nage n'a pas pu être affiché. Ce problème peut survenir pour diverses raisons, telles que des problèmes de connectivité réseau, des problèmes de serveur ou un formatage incorrect des données."

        }
    },

    navigation: {
        allFieldsRequired: "Tous les champs avec (*) sont obligatoires.",
        anErrorOcured: "Une erreur s'est produite, veuillez réessayer plus tard",
        anErrorOcuredProcessNotFund: "Le processus n'a pas été trouvé.",
        anErrorOccurredMappNotFound: "La map n'a pas été trouvée",
        assigningProcessFolder: "Assigner un processus à un dossier",
        approvalCannotCreateByUser: "Vous ne pouvez pas inviter à approuver en tant que collaborateur.",
        backHome: "Revenir à l'accueil",
        contactUs: "Contactez-nous",
        diagramName: "Nom du diagramme",
        diagramsPendingApproval: "Diagrammes en attente d'approbation",
        enterValideEmail: "Entrez un email valide",
        folderSuccessfully: "Dossier mis à jour avec succès.",
        folderNotFound: "Erreur : Le dossier n'a pas été trouvé",
        inviteOwner: "Inviter un propriétaire de processus",
        inviteAApprover: "Inviter un approbateur",
        inviteCollaborator: "Inviter un collaborateur",
        invitationSentSuccessfully: "Invitation envoyée avec succès",
        logOut: 'Se déconnecter',
        markAsFinal: "Marquage final",
        mapWithNotFound: "Une erreur s'est produite : La map n'a pas été trouvée.",
        noRequest: "Aucune demande d'approbation",
        privacyHub: 'Confidentialité',
        shareLink: "Partager le lien",
        selectFolder: "Sélectionner un dossier",
        successfullyUpdated: "Mise à jour effectuée avec succès",
        termsOfUse: "Conditions d'utilisation",
        theProcessHasBeenHalted: "Le processus a été arrêté avec succès.",
        privacyPolicy: "Politique de confidentialité",
        processClassifiedWithSuccess: "Processus classé avec succès",
        processCannot: "Vous ne pouvez pas mettre à jour ce processus.",
        processNotFound: "Le processus n'a pas pu être trouvé.",
        proJectNotFound: "Le project n'a pas pu être trouvé.",
        publishQuestion: "Marquer comme final ? Cela rendra le processus en lecture seule et empêchera toute modification ultérieure.",
        youAreNotLoggedIn: "Vous n'êtes pas connecté ",
        youAreNotAuthorized: "Vous n'êtes pas autorisé à mettre à jour ce dossier.",
        youAreNotAuthorizedToPerformThisAction: "Vous n'êtes pas autorisé à effectuer cette action."
    },

    layout: {
        header: {
            approvalRequests: "Demandes d'approbation",
            upgradeToPremium: " Passer à la version premium",
            updateCompany: 'Mise à jour de la compagnie',
        }
    },

    views: {
        auth: {
            accountAlreadyExist: "Compte existant",
            alreadyHaveAccount: "Vous avez déjà un compte ?",
            anErrorOcured: "Une erreur s'est produite, veuillez réessayer plus tard",
            compagnyNameIsRequired: "Le nom de l'entreprise est obligatoire",
            continueWithApple: "Continuer avec Apple",
            continueWithGoogle: "Continuer avec Google",
            continueWithMicrosoft: "Continuer avec Microsoft",
            dontHaveAccount: "Vous n'avez pas de compte ?",
            eliminate: "Éliminer les gaspillages, réduire les coûts et maximiser les profits grâce aux méthodologies Lean Six Sigma éprouvées.",
            emailAddres: "Adresse email",
            emailIsRequired: "L'e-mail est obligatoire",
            emailAddressIs: "L'adresse email est incorrecte ou n'existe pas",
            establish: "Établir des rôles et des responsabilités clairs, éliminer l'ambiguïté, optimiser la collaboration et favoriser la réussite de l'équipe.",
            forgotPassword: "Mot de passe oublié ?",
            getStarted: "Commencer",
            incorrectEmailOrPassword: "Email ou mot de passe invalide",
            logIn: "Se connecter",
            msgReset: "Nous vous enverrons des instructions pour réinitialiser votre mot de passe après avoir saisi votre adresse électronique.",
            newPassword: "Nouveau mot de passe",
            newPassWordIsRequired: "Le nouveau mot de passe est obligatoire",
            password: "Mot de passe",
            passwordsNotMatch: "Les mots de passe ne correspondent pas",
            passwordIsRequired: "Le mot de passe est obligatoire",
            passwordSuccessfully: "Mot de passe réinitialisé avec succès",
            resetYourPassword: 'Réinitialiser votre mot de passe',
            reset: 'Réinitialiser',
            signUp: "S'inscrire",
            seamlessly: "Documentez vos processus en toute transparence et atteignez la conformité ISO 9001:2015 en toute confiance.",
            thisEmailHasBanned: "Cette adresse e-mail a été retirée de Famla",
            userDoesNotExist: "L'utilisateur n'existe pas",
            verifyYourEmail: "Veuillez vérifier votre adresse e-mail",
            yourSessionHasExp: "Votre session a expiré ou vous êtes connecté sur un autre appareil."

        },
        processView: {
            areYouSure: " Êtes-vous sûr de vouloir supprimer cet élément ?",
            folder: "Dossier",
            myProcess: 'Mes processus',
            newProcess: 'Nouveau processus',
            needInspiration: "Besoin d'inspiration ? Explorez ces cas d'usage populaires",
            library: "Librairie",
            popularUseCase: "Cas d'usage populaires",
            processNotFound: "Processus non trouvé",
            viewAll: 'Voir tous'
        },
        processInterview: {
            addCollaborators: "Ajouter un collaborateur",
            collaborators: "Collaborateurs",
            contributor: "Contributeur",
            comment: "Commentaire",
            diagramsList: "Liste des diagrammes",
            mapReady: "Diagramme prêt dans 10 minutes",
            newProcess: "Nouveau processus",
            reply1: "Fin de l'entretien, merci pour votre contribution.",
            reply2: "Fin de l'entretien, merci pour votre contribution. Nous contacterons l'équipe et reviendrons vers vous ultérieurement.",
            reply3: "J'ai terminé les entretiens, cliquez sur la carte de génération pour voir le résultat.",
            reply4: "Pouvez-vous dresser la liste des collaborateurs manquants ?",
            reply5: "Souhaitez-vous ajouter quelque chose d'autre ou une information supplémentaire que vous jugez utile de nous communiquer ?",
            recentProcesses: "Processus récents",
            // eslint-disable-next-line no-template-curly-in-string
            s1: "Votre diagramme de processus est prêt !-En tant que responsable de processus, vous avez économisé {{time}} minutes d'entretien !",
            // eslint-disable-next-line no-template-curly-in-string
            s2: "Votre diagramme de processus est prêt !-{{time}} minutes d'entretien récupérées ! Famla donne du pouvoir aux responsables de processus comme vous.",
            // eslint-disable-next-line no-template-curly-in-string
            s3: "Votre diagramme de processus est prêt !-{{time}} minutes gagnées sur les entretiens. Responsables de processus, Famla rationalise votre travail.",
            yourResponse: "Réponse"
        },
        processByGroupView: {
            approvalName: "Approbateur",
            approvalDate: "Date approbation",
            dateFormat: "La date doit être au format YYYY-MM-DD HH:MM:SS",
            diagramLink: "Lien du diagramme",
            formatDate: "AAAA-MM-JJ HH:mm:ss",
            interviewTime: "Temps d'interview gagné",
            lastModifiedDate: "Dernière modification",
            noProcess: "Aucun processus",
            nexRevisionDate: "Prochaine révision",
            ownerName: "Propriétaire",
            processName: "Nom du processus",
            processMetrics: "KPIs processus",
            revisionTriggers: "Motifs révision"

        },

        legalDocs: {
            contact: {
                eitherFill: "Vous pouvez remplir le formulaire ou utiliser les coordonnées ci-contre.",
                gettingInTouch: "La prise de contact est facile!",
                sendMeCopy: "Envoyer une copie",
            },

            faq: {
                contactUsPage: ' page Contactez-nous',
                p1: "Pensez à vos processus comme à l'ADN de votre organisation - ils sont le schéma directeur de la façon dont les choses sont faites ! Famla a été créé pour aider les organisations de toutes tailles, industries et sites à comprendre, documenter et rationaliser ces flux de travail essentiels. Alors, qu'est-ce qui fait de Famla l'outil idéal pour vous ? Il s'agit de simplicité, de rapidité et d'un puissant moteur d'amélioration de l'IA qui vous aide à travailler plus intelligemment, et non plus difficilement.",
                p2: "Cette première version se concentre sur les diagrammes transversaux (swimlane). Ces diagrammes sont parfaits pour visualiser le flux de travail entre différentes équipes ou départements. Nous ajouterons bientôt d'autres types de diagrammes !",
                p3: "Oui ! Nous prenons en charge les notations communes telles que BPMN (Business Process Model and Notation) afin de garantir la cohérence et la facilité de compréhension.",
                p4: "Bien sûr. Exportez vos cartes de processus sous forme d'images (PNG, JPEG) ou de fichiers PDF - parfaits pour les rapports, les présentations ou pour obtenir un retour d'information. Vous pouvez également inviter des personnes à visualiser les cartes terminées directement dans l'outil pour une collaboration rapide.",
                p5: "Absolument ! Nous avons conçu cet outil en pensant au travail d'équipe. Invitez facilement les membres de votre équipe à collaborer sur des cartes en temps réel, afin de faire de l'amélioration des processus un effort partagé.",
                p6: "Pas encore, mais c'est pour bientôt ! Nous savons à quel point il est important de tout organiser, c'est pourquoi nous travaillons sur la possibilité de joindre des fichiers, des liens, des notes, etc. directement dans vos cartes.",
                p7: "Préparez-vous à la magie des processus ! Notre moteur d'IA analyse vos cartes en utilisant les principes Lean Six Sigma pour identifier les domaines dans lesquels vous pouvez rationaliser et améliorer vos processus.",
                p8: "Définitivement. Nous prenons la sécurité des données au sérieux. Notre plateforme est hébergée sur Google Cloud, ce qui vous garantit une sécurité optimale grâce au cryptage et à toutes les mesures de protection les plus récentes.",
                p9: "Après votre inscription, un membre de notre équipe vous contactera pour discuter de vos besoins et de l'abonnement qui vous convient le mieux. En attendant, plongez dans l'outil, explorez-le et découvrez la puissance de Famla de première main !",
                p10: "Notre équipe d'assistance est là pour vous ! N'hésitez pas à nous contacter à tout moment via notre ",
                p11: "Nous travaillons constamment à l'amélioration de Famla et nous vous invitons à nous faire part des fonctionnalités que vous aimeriez voir apparaître.",
                t1: "Famla est-elle la solution idéale pour mon entreprise ? ",
                t2: "Quels types de diagrammes de processus puis-je construire avec cet outil ?",
                t3: "Cet outil est-il compatible avec les normes de cartographie des processus que je connais déjà ?",
                t4: "Puis-je facilement partager mes cartes terminées avec d'autres personnes ?",
                t5: "Mon équipe et moi-même pouvons-nous travailler ensemble sur une carte de processus ? ",
                t6: "Puis-je connecter d'autres informations à mes cartes de processus ?",
                t7: "Comment cet outil peut-il m'aider à améliorer mes processus ? ",
                t8: "Les données de ma carte de processus sont-elles sécurisées ?",
                t9: "Proposez-vous un abonnement gratuit ou une période d'essai ?",
                t10: "Comment puis-je obtenir de l'aide si j'en ai besoin ?",
            },

            privacy: {
                p1: "Chez Famla AI, nous sommes profondément attachés à la confidentialité et à la sécurité des données de votre entreprise. Nous comprenons le rôle critique que joue l'intégrité des données dans le déploiement réussi des solutions d'IA, et nous avons mis en œuvre des mesures robustes pour assurer leur protection. Cette politique décrit les principes qui guident nos pratiques en matière de collecte, d'utilisation et de sécurité des données.",
                p2: "Nous pouvons intégrer des services tiers pour améliorer certaines fonctionnalités de notre solution d'IA. Ces tiers n'auront accès qu'aux données nécessaires à l'exécution de leurs fonctions spécifiques en notre nom. Nous exigeons de tous nos partenaires des garanties strictes en matière de confidentialité et de sécurité.",
                p3: "Nous réviserons cette politique au fur et à mesure de l'évolution de nos solutions d'IA et des réglementations en matière de protection de la vie privée. Vous serez informé de tout changement significatif et la politique mise à jour sera publiée sur notre site web.",
                p4: "Si vous avez des questions concernant notre politique de confidentialité et de sécurité, veuillez nous contacter à l'adresse suivante",
                privacyAndSecurityPolicy: "Politique de confidentialité et de sécurité",
                t1: "Notre engagement",
                t2: "Informations collectées",
                t3: "Comment nous utilisons vos données",
                t4: "Mesures de sécurité",
                t5: "Protection des droits",
                t6: "Services aux tiers",
                t7: "Mises à jour de la politique",
                ulLi1: "Données requises pour les opérations : Pour fournir nos services d'IA, nous collecterons les données minimales nécessaires pour former, améliorer et fournir des informations par le biais de nos modèles. Ces données peuvent inclure l'organigramme, le nom du client, les données de performance des activités.",
                ulLi2: "Données d'utilisation : Nous pouvons collecter des données d'utilisation anonymes pour comprendre comment notre solution d'IA est utilisée, ce qui nous permet d'optimiser ses performances et de l'adapter aux besoins de votre entreprise.",
                ulLi3: "Informations essentielles sur le compte Pour gérer votre compte et fournir une assistance à la clientèle, nous pouvons recueillir des informations de contact de base telles que le nom de votre entreprise, votre adresse et les adresses électroniques des utilisateurs autorisés.",
                ulLi4: "Amélioration du service : Nous pouvons utiliser des données d'utilisation agrégées et anonymes pour comprendre et améliorer notre solution d'IA pour tous nos clients.",
                ulLi5: "Assistance à la clientèle : Nous pouvons utiliser les informations relatives à votre compte pour fournir une assistance technique, communiquer avec vos utilisateurs autorisés et répondre à vos demandes.",
                ulLi6: "Marketing et informations sur mesure : Nous pouvons analyser des données anonymes et agrégées dérivées de votre utilisation afin de fournir des informations sectorielles plus pertinentes, des tendances et éventuellement des publicités liées à nos services ou aux offres de nos partenaires.",
                ulLi7: "Cryptage : Nous utilisons des méthodes de cryptage standard pour protéger vos données en transit et au repos.",
                ulLi8: "Stockage sécurisé des données : Notre solution d'IA est hébergée sur une infrastructure en nuage sécurisée et gérée par des professionnels, qui fait l'objet d'audits de sécurité réguliers.",
                ulLi9: "Contrôles d'accès : L'accès à vos données est strictement contrôlé et n'est accordé qu'au personnel autorisé qui est contractuellement lié par des accords de confidentialité.",
                ulLi10: "Réponse aux incidents : Nous avons mis en place un plan détaillé de réponse aux incidents afin de remédier rapidement à d'éventuelles violations de la sécurité. En cas de violation, nous vous en informerons conformément à la réglementation en vigueur",
                ulLi11: "Transparence des données : Nous fournissons des informations claires sur la manière dont vos données sont utilisées dans le cadre de cette politique et dans tout accord contractuel.",
                ulLi12: "Accès aux données : Vous avez le droit d'accéder aux données que nous collectons et traitons au nom de votre entreprise.",
                ulLi13: "Rectification des données : Vous avez le droit de demander la correction de toute donnée personnelle inexacte que nous détenons.",
                ulLi14: "Suppression des données : À la fin de votre contrat de service, vous pouvez demander la suppression ou la restitution de vos données conformément à la réglementation en vigueur.",
            },

        }


    },

    words: {
        address: "Adresse",
        approve: "Approuver",
        cancel: 'Annuler',
        close: 'Fermer',
        comments: "Commentaire",
        country: "Pays",
        create: "Créer",
        city: "Ville",
        description: 'Description',
        delete: "Supprimer",
        disApprove: "Rejeter",
        edit: "Modifier",
        email: "Email",
        english: "Anglais",
        faq: "FAQ",
        french: "Français",
        file: "Fichier",
        help: 'Aide',
        invite: "Inviter",
        inviteAApprover: "Inviter un approbateur",
        languages: "Langues",
        link: "Lien",
        me: "Moi",
        message: "Décrivez votre demande ici",
        name: "Nom",
        no: "non",
        organize: "Déplacer le dossier",
        or: "OU",
        refresh: "Rafraîchir",
        rename: "Renomer",
        requestApproval: "Demande d'approbation",
        role: "Role",
        publish: "Publier",
        process: 'Processus',
        save: "Sauvegarder",
        share: "Partager",
        searching: "Recherche",
        search: "Recherche",
        send: "Envoyer",
        subject: "Sujet",
        yes: "oui",


    },




}



export default fr