// React Component
import React from 'react'

// App Component
import { AppLayout, HeaderLayout } from '../../layouts'

// Features
import CompanyCreate from '../../features/company'

const CompanyCreateView = () => {
    return (
        <AppLayout>
            <div className="mt-3 px-3">
                <div className='fixed-top'>
                    <HeaderLayout loadcompany={false} />
                </div>
                <div className='container'>
                    <CompanyCreate />
                </div>

            </div>
        </AppLayout>
    )
}

export default CompanyCreateView