
//React
import React, { CSSProperties } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


//component
import { logout, useAppDispatch } from "../stores"
import { Routes } from '../routes'



//interface

interface ChildProps {
    dropName?: string
    iconClassName?: string
    buttonDropClassName?: string
    dropdownType?: string
    styleButton?: CSSProperties
    deleteProcess?: () => void
    action1?: string
    action2?: string
    action3?: string
    action4?: string
    action5?: string
    action6?: string
    theRouteOfAction5: string
    theRouteOfAction4: string
    dataBsTarget?: string
    faq?: string
    routeFaq?: string
    updateMyCompany?: string
    edit?: string
    dataBsTargetEdit?: string
    publish?: string
    dataBsTargetPublish?: string
    dataBsTargetAction6?: string
    aproval?: string
    rename?: string
    share?: string
    invitedAproval?: () => void
    renameSwimlane?: () => void
    shareSwimlane?: () => void
    activeFocusdisa?: () => void
    activeFocusa?: () => void
    runEditor?: () => void
    backToHome?: () => void
    onClick?: (event: React.MouseEvent) => void
    exportToPDF?: () => void
    exportToPNG?: () => void
    changeLanguage?: (lang: string) => void
    requestApproval?: string
    close?: string
    approve?: string
    disapprove?: string
    editdiadram?: string
    exportt?: boolean
    lang?: boolean
    dataBsTargetAction7?: string
    action7?: string


}

const Dropdown = (props: ChildProps) => {
    const {
        close,
        approve,
        disapprove,
        editdiadram,
        onClick,
        invitedAproval,
        requestApproval,
        runEditor,
        backToHome,
        activeFocusdisa,
        activeFocusa,
        renameSwimlane,
        shareSwimlane,
        aproval,
        rename,
        share,
        dataBsTargetPublish,
        dataBsTargetAction6,
        publish,
        routeFaq,
        faq,
        dropName,
        iconClassName,
        buttonDropClassName,
        dropdownType,
        styleButton,
        deleteProcess,
        exportToPDF,
        exportToPNG,
        changeLanguage,
        action1,
        action2,
        action3,
        action4,
        action5,
        action6,
        action7,
        theRouteOfAction5,
        theRouteOfAction4,
        dataBsTarget,
        updateMyCompany,
        dataBsTargetEdit,
        edit,
        exportt,
        lang,
        dataBsTargetAction7
    } = props
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const logOut = () => {
        dispatch(logout())
        navigate(Routes.AUTH_LOGIN)
    }



    return (
        <>
            <div onClick={onClick} className={dropdownType} >
                <button style={styleButton} className={`dropdown-toggle ${buttonDropClassName}`} type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                    <span><i className={iconClassName}></i></span>{dropName}
                </button>
                <ul className="dropdown-menu dropdown-menu-dark small" aria-labelledby="dropdownMenuButton2">
                    {action3 && <li><a className="dropdown-item" data-bs-target={dataBsTarget} data-bs-toggle='modal' href="#/">{action3}</a></li>}
                    {aproval && <li><button onClick={invitedAproval} className="dropdown-item  text-decoration-none border-0">{aproval}</button></li>}
                    {rename && <li><button onClick={renameSwimlane} className="dropdown-item  text-decoration-none border-0">{rename}</button></li>}
                    {share && <li><button onClick={shareSwimlane} className="dropdown-item  text-decoration-none border-0">{share}</button></li>}
                    {requestApproval && <li><a className="dropdown-item" data-bs-target={'#requestApproval'} data-bs-toggle='modal' href="#/">{requestApproval}</a></li>}
                    {updateMyCompany && <li><a className="dropdown-item" data-bs-target={'#changeCompany'} data-bs-toggle='modal' href="#/">{updateMyCompany}</a></li>}
                    {faq && <li><Link className="dropdown-item  text-decoration-none " to={routeFaq ? routeFaq : ""}>{faq}</Link></li>}
                    {publish && <li><a className="dropdown-item" data-bs-target={dataBsTargetPublish} data-bs-toggle='modal' href="#/">{publish}</a></li>}
                    {action1 && <li><button onClick={deleteProcess} className="dropdown-item  text-decoration-none border-0">{action1}</button></li>}
                    {action7 && <li><a className="dropdown-item" data-bs-target={dataBsTargetAction7} data-bs-toggle='modal' href="#/">{action7}</a></li>}

                    {action4 && <li><Link className="dropdown-item text-decoration-none" to={theRouteOfAction4}>{action4}</Link></li>}
                    {action6 && <li><a className="dropdown-item" data-bs-target={dataBsTargetAction6} data-bs-toggle='modal' href="#/">{action6}</a></li>}
                    {action2 && <li><button onClick={() => logOut()} className="dropdown-item text-decoration-none border-0" >{action2}</button></li>}
                    {edit && <li><a className="dropdown-item" data-bs-target={dataBsTargetEdit} data-bs-toggle='modal' href="#/">{edit}</a></li>}
                    {action5 && <li><Link className="dropdown-item  text-decoration-none " to={theRouteOfAction5}>{action5}</Link></li>}

                    {disapprove && <li><button onClick={activeFocusdisa} data-bs-target={'#disapprove'} data-bs-toggle={'modal'} className="dropdown-item text-theme fw-bold">{disapprove}</button></li>}
                    {approve && <li><button onClick={activeFocusa} data-bs-target={'#approve'} data-bs-toggle={'modal'} className="dropdown-item text-success fw-bold">{approve}</button></li>}
                    {editdiadram && <li> <button className="dropdown-item" onClick={runEditor}>{editdiadram}</button></li>}

                    {exportt &&
                        <>
                            <li onClick={exportToPDF}><a className="dropdown-item" href="#/">{t('features.swimlane.exportPDF')}</a></li>
                            <li onClick={exportToPNG}><a className="dropdown-item" href="#/">{t('features.swimlane.exportPNG')}</a></li>
                        </>
                    }
                    {lang &&
                        <>
                            <li onClick={() => changeLanguage?.('fr')}><a className="dropdown-item" href="#/">{t('words.french')}</a></li>
                            <li onClick={() => changeLanguage?.('en')}><a className="dropdown-item" href="#/">{t('words.english')}</a></li>
                        </>
                    }

                    {close && <li onClick={backToHome}><a className="dropdown-item" href="#/">{close}</a></li>}


                </ul>
            </div>


        </>


    )
}
export default Dropdown