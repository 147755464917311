
//React
import React, { useRef, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom'

//App
import { Spiner, TextAreaForm } from "../../components"
import { FamlaServices } from '../../services';
import { useAppSelector } from '../../stores';
import { Routes } from '../../routes'
import { iUser } from '../../components/type'
import Area from "./area"
import ContentQuestion from "./contentQuestion"
import Question from "./question"
import { ButtonGroup } from '../../components';

//Interface

interface iQuestion {
    question_text: string;
    content: string;
    _id: string;
    step: number[];
    invited: string;

}

interface StateType {
    questionAndResponse: any[];
    interviewId: string;
    answer: string;
    isLoading: boolean
    processTitle: string
    email: string,
    isSubmit: boolean,
    role: string,
    isLoadingProcess: boolean,
    autorisation: boolean,

}

interface chilprops {
    time: Number
    interviewwId: string | undefined
    id: string | undefined
    setInvited: (invited: string | undefined) => void
    swimlanes: Array<any>
    collaborator: iUser
    procesId: string
}


const ChatBot = (props: chilprops) => {
    const { time, interviewwId, id, setInvited, swimlanes, collaborator, procesId } = props
    const { t } = useTranslation()
    const refchat = useRef<HTMLDivElement>(null)
    const [idLastAnwser, setIdLastAnwser] = React.useState("")
    const [isInputBlocked, setIsInputBlocked] = useState(false);
    const users = useAppSelector((state) => state.auth);
    const [spinner, setSpinner] = React.useState(false)
    const [currentIndex, setCurrentIndex] = React.useState<number>(0);
    const role = useAppSelector(state => state.role.role)
    const navigate = useNavigate()
    const [state, setState] = React.useState<StateType>({
        questionAndResponse: [],
        interviewId: "",
        answer: "",
        isLoading: true,
        processTitle: "",
        email: "",
        isSubmit: false,
        role: "",
        isLoadingProcess: true,
        autorisation: true,
    })

    const [thumbs, setThumbs] = useState({
        up: false,
        down: false,
        comment: "",
        submit: false,
        close: false
    })
    const lastQuestion = state.questionAndResponse[state.questionAndResponse.length - 1];

    const endReply = t('views.processInterview.reply1')
    const endReply0 = t('views.processInterview.reply2')
    const other = t('views.processInterview.reply3')
    const referenceQuestion = t('views.processInterview.reply4')
    const referenceQuestion1 = t('views.processInterview.reply5')

    React.useEffect(() => {
        const getMyInterviewByProcess = async () => {
            let newData = await FamlaServices("api_host", `/answer/${interviewwId}`, "GET", "", users.access_token);
            if (newData.status === 200) {
                const dataQuestionAndResponse = newData.body.data
                const reverveData = dataQuestionAndResponse.reverse()
                setState((prev) => ({ ...prev, questionAndResponse: reverveData, isLoading: false }))
            }

            setState((prev) => ({ ...prev, isLoading: false }))
        }

        getMyInterviewByProcess()

    }, [id, interviewwId, state.interviewId, procesId, users.access_token])




    React.useEffect(() => {
        if (lastQuestion && lastQuestion.thumbs) {
            setThumbs({
                up: lastQuestion.thumbs.up === true,
                down: false,
                comment: "",
                submit: false,
                close: false
            });
        }
    }, [lastQuestion]);

    React.useEffect(() => {
        if (refchat.current) {
            refchat.current.scrollTop = refchat.current.scrollHeight;
        }
        if (state.questionAndResponse?.length > 0) {
            setIdLastAnwser(state.questionAndResponse[state.questionAndResponse.length - 1]?._id);
        }

        let shouldBlockInput = false;
        state.questionAndResponse.forEach((question, index) => {
            if (
                (
                    (question.step[0] === 1 && question.step[1] === 4 && question.question_text !== referenceQuestion) ||
                    (question.step[0] === 3 && question.step[1] === 5 && question.question_text !== referenceQuestion) ||
                    question.question_text === referenceQuestion1
                ) && index === state.questionAndResponse.length - 1
            ) {
                shouldBlockInput = true;
            } else if (
                (question.step[0] === 1 && question.step[1] === 3 && index === state.questionAndResponse.length - 1) ||
                (question.step[0] === 1 && question.step[1] === 5 && index === state.questionAndResponse.length - 1)
            ) {
                shouldBlockInput = true;
            }

            if (question.step[0] === 1 && question.step[1] === 8) {
                window.location.assign(`/process/interviews/${interviewwId}`);
            }

        });

        setIsInputBlocked(shouldBlockInput);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.questionAndResponse, state.answer, id, spinner, currentIndex, thumbs.down]);


    const sendAnswer = async (e: React.MouseEvent<HTMLButtonElement>, answerId: string | undefined, answerA: string, answer_id: string) => {
        setSpinner(true)
        const updateAnswerOfLastElement = [...state.questionAndResponse];
        const lastItemIndex = updateAnswerOfLastElement.length - 1;
        if (lastItemIndex >= 0) {
            updateAnswerOfLastElement[lastItemIndex] = { ...updateAnswerOfLastElement[lastItemIndex], content: answerA };
        }
        setState((prevState) => ({ ...prevState, questionAndResponse: updateAnswerOfLastElement, answer: "", }));

        for (let question of updateAnswerOfLastElement) {
            if (question.step[0] === 1 && question.step[1] === 5) {
                setInvited(question.invited)
            }
        }
        const answer = {
            content: answerA,
            answer_id: answer_id
        };

        let data = await FamlaServices("api_host", `/answer/${answerId}`, "PUT", answer, users.access_token);
        if (data.status === 200) {
            const newQuestionAndResponse = { ...data.body.data };
            const updatedListWithNewQuestionAndResponse = [...updateAnswerOfLastElement, newQuestionAndResponse];
            setState((prevState) => ({
                ...prevState,
                questionAndResponse: updatedListWithNewQuestionAndResponse,
                interviewId: state.interviewId,
                answer: "",
            }));


        }
        setSpinner(false)
        setThumbs({ ...thumbs, up: false, down: false })
    };

    const updateAnswerWithUpDown = async (upDown: string, answerId: string) => {
        setThumbs(prevThumbs => ({ ...prevThumbs, submit: true, up: upDown === 'up', down: upDown === 'down' }));

        const form = {
            up: upDown === 'up',
            comment: thumbs.comment
        };

        try {
            const data = await FamlaServices("api_host", `/answer/thumbs/${answerId}`, "PUT", form, users.access_token);

            if (data.status === 200) {
                setThumbs({
                    up: upDown === 'up',
                    down: upDown === 'down',
                    comment: "",
                    submit: false,
                    close: true
                });
            } else {
                setThumbs(prevThumbs => ({ ...prevThumbs, submit: false }));
            }
        } catch (error) {
            setThumbs(prevThumbs => ({ ...prevThumbs, submit: false }));
        }
    }

    const shouldDisplayActionButtons = (question: iQuestion, index: number, totalQuestions: number): boolean => {
        return (
            ((((question.step[0] === 1 && question.step[1] === 4 && question.question_text !== referenceQuestion) ||
                (question.step[0] === 3 && question.step[1] === 5 && question.question_text !== referenceQuestion) ||
                question.question_text === referenceQuestion1) &&
                index === totalQuestions - 1) ||
                (question.step[0] === 1 && question.step[1] === 3 && index === totalQuestions - 1))
        );
    };

    function setThumbsUpDown(upDown: string) {
        setThumbs(prevThumbs => ({
            ...prevThumbs,
            up: upDown === 'up',
            down: upDown === 'down'
        }));
    }


    const handleChange = (event: any) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'area') {
            setState({ ...state, answer: fieldValue })
        }
        if (name === 'area1') {
            setThumbs({ ...thumbs, comment: fieldValue })
        }
        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'email') {
            setState((prev) => ({ ...prev, email: fieldValue }))
        } else if (name === 'role') {
            setState({ ...state, role: fieldValue })
        }
    };

    function tag() {
        const lastQuestion = state.questionAndResponse[state.questionAndResponse.length - 1]?.question_text;
        return lastQuestion !== endReply0 && lastQuestion !== endReply
    }


    return (
        <div className="w-75 m-auto">
            <div className="overflow-auto custom-vh fw-bold" ref={refchat}>
                {state.isLoading ?
                    <div className="text-center text-start">
                        < Spiner className="d-flex justify-content-center " />
                    </div>
                    :
                    <>
                        {state.questionAndResponse.map((question: { question_text: string; content: string, _id: string, step: never[], invited: string }, index: number) => {

                            return (
                                <div key={question._id}>
                                    <div className="mt-4">
                                        <Question
                                            key={index}
                                            index={index}
                                            currentIndex={currentIndex}
                                            questionText={question.question_text}
                                            questionAndResponseLength={state.questionAndResponse.length}
                                            setCurrentIndex={setCurrentIndex}
                                        />

                                        {shouldDisplayActionButtons(question, index, state.questionAndResponse.length) &&
                                            <ButtonGroup
                                                buttons={[
                                                    {
                                                        text: t('words.yes'),
                                                        onClick: (e) => sendAnswer(e, interviewwId, t('words.yes'), question._id)
                                                    },
                                                    {
                                                        text: t('words.no'),
                                                        onClick: (e) => sendAnswer(e, interviewwId, t('words.no'), question._id)
                                                    }
                                                ]}
                                                className="ps-4"
                                                ariaLabel="Basic radio toggle button group"
                                            />
                                        }
                                        {(question.step[0] === 1 && question.step[1] === 5 && index === state.questionAndResponse.length - 1) &&
                                            <ButtonGroup
                                                buttons={[
                                                    {
                                                        text: t('words.yes'),
                                                        onClick: (e) => sendAnswer(e, interviewwId, t('words.yes'), question._id),
                                                        dataBsTarget: '#inviteUser',
                                                        dataBsToggle: 'modal'
                                                    },
                                                    {
                                                        text: t('words.no'),
                                                        onClick: (e) => sendAnswer(e, interviewwId, t('words.no'), question._id)
                                                    }
                                                ]}
                                                className="ps-4"
                                                ariaLabel="Basic radio toggle button group"
                                            />

                                        }
                                        {index === state.questionAndResponse.length - 1 && question.question_text !== endReply0 && question.question_text !== other && question.question_text !== endReply && (
                                            <ButtonGroup
                                                buttons={[
                                                    {
                                                        text: '',
                                                        onClick: () => updateAnswerWithUpDown('up', question._id),
                                                        icon: thumbs.up ? <i className="fa-solid fa-thumbs-up"></i> : <i className="fa-regular fa-thumbs-up"></i>,
                                                        isSelected: thumbs.up
                                                    },
                                                    {
                                                        text: '',
                                                        onClick: () => setThumbsUpDown('down'),
                                                        icon: thumbs.down ? <i className="fa-solid fa-thumbs-down"></i> : <i className="fa-regular fa-thumbs-down"></i>,
                                                        isSelected: thumbs.down
                                                    }
                                                ]}
                                                className="ps-4 justify-content-end"
                                                ariaLabel="Basic radio toggle button group"
                                            />

                                        )}
                                        {(thumbs.down && index === state.questionAndResponse.length - 1 && !thumbs.close) && (
                                            <TextAreaForm
                                                name="area1"
                                                sendButton={thumbs.comment.trim().length > 0}
                                                label=""
                                                rows={1}
                                                classNameArea="form-control w-100 bg-dark text-light text-bg-dark py-2 rounded-2 pe-5 border-theme mb-2"
                                                classNameContainer="w-50"
                                                placeholder={t('views.processInterview.comment') + '...'}
                                                onChange={(e) => handleChange(e)}
                                                value={thumbs.comment}
                                                interviewId={state.interviewId}
                                                sendAnswer={() => updateAnswerWithUpDown("down", question._id)}
                                                spinner={thumbs.submit}
                                                maxRows={5}
                                                minRows={3}
                                                button={true}
                                                readOnly={false}
                                            />
                                        )}

                                    </div>
                                    {(question.content?.length > 0 || question.content !== null) &&
                                        <ContentQuestion fullname={collaborator.fullname} content={question.content} />
                                    }

                                </div>)
                        }

                        )}

                    </>
                }

            </div>

            <Area
                state={state}
                handleChange={handleChange}
                sendAnswer={sendAnswer}
                interviewwId={interviewwId}
                idLastAnwser={idLastAnwser}
                tag={tag}
                t={t}
                spinner={spinner}
                isInputBlocked={isInputBlocked}
                swimlanes={swimlanes}
                role={role}
                time={time}
            />
        </div >
    )
}

export default ChatBot