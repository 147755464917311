// Reac Component
import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'

// App Component
import { FamlaServices } from '../../services'
import { Buttons, InputForm } from '../../components'
import { Routes } from '../../routes'


// Features

import ButtonSocialeNetwork from './ButtonSocialeNetwork'
import { Metrics } from '../../constants'

//interface
import { iAlert } from '../../interfaces'

const UsersSignin = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [compagny, setCompagny] = React.useState({
        name: '',
        email: '',
        industry: '',
        password: '',
        isSubmit: false,
        isValidMail: true
    })
    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();


        if (compagny.email.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.emailIsRequired') });
            return;
        }
        else if (compagny.name.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.compagnyNameIsRequired') });
            return;
        }

        let formData = {
            fullname: compagny.name,
            email: compagny.email,
            password: compagny.password,
            passwordConfirm: compagny.password
        }
        setCompagny({ ...compagny, isSubmit: true });
        let data = await FamlaServices("api_host", "/auth/register", "POST", formData, "")

        if (data.status === 201) {
            setCompagny({ ...compagny, name: '', email: '', password: '', isSubmit: false, isValidMail: true });
            navigate(Routes.AUTH_MAIL);
        }
        else if (data.body.status_code === 409) {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.accountAlreadyExist') });
            setCompagny({ ...compagny, password: '', isSubmit: false });
        }
        else if (data.body.status_code === 400 && data.body.description === "This email address has been banned from Famla") {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.thisEmailHasBanned') });
            setCompagny({ ...compagny, password: '', isSubmit: false });
        }
        else if (data.body.status_code === 400 && data.body.description === "Passwords do not match") {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.passwordsNotMatch') });
            setCompagny({ ...compagny, password: '', isSubmit: false });
        }
        else {
            setCompagny({ ...compagny, password: '', isSubmit: false });
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.anErrorOcured') });
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { type, name, value, checked } = event.target;
        let fieldValue: string | number | boolean;
        if (type === 'checkbox') {
            fieldValue = checked;
        } else if (type === 'number') {
            fieldValue = value !== '' ? Number(value) : '';
        } else {
            fieldValue = value !== undefined ? value : '';
        }
        fieldValue = typeof fieldValue !== 'string' ? String(fieldValue) : fieldValue;
        const trimmedValue: string = typeof fieldValue === 'string' ? fieldValue.trim() : '';
        let isEmailValid = name === 'email' ? Metrics.mailFormat.test(trimmedValue) : true;

        setCompagny({ ...compagny, [name]: fieldValue, isValidMail: isEmailValid });
    };

    return (
        <div className="w-75">
            <div className="mb-4">
                <h1 className="mb-1 fw-bold text-white fs-3">{t('views.auth.signUp')}</h1>
            </div>
            <form >
                {alert.isActive &&
                    <div className={`alert alert-${alert.type}`} role="alert">
                        {alert.message}
                    </div>
                }

                <InputForm
                    inputType="email"
                    inputLabel={t('views.auth.emailAddres')}
                    inputName="email"
                    inputValue={compagny.email}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    inputContainerClass={compagny.isValidMail ? 'mb-3' : 'mb-0'}
                />
                {!(compagny.isValidMail) && <span className={`text-danger fs-6 d-bloc ${!compagny.isValidMail ? 'mb-3' : 'mb-0'}`}>{compagny.isValidMail ? '' : 'Enter a valid mail'}</span>}

                <InputForm
                    inputType="text"
                    inputLabel={t('words.name')}
                    inputName="name"
                    inputValue={compagny.name}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    inputContainerClass={compagny.isValidMail ? 'mb-3' : 'mb-0'}
                />
                {/* <SelectForm
                    formClass="mb-3"
                <SelectForm
                    selectClass="mb-3"
                    placeholder="Select Industry"
                    selectName={compagny.industry}
                    selectOptions={selectOptions}
                    onChange={(e) => handleChange(e)}
                /> */}
                <InputForm
                    inputType="password"
                    inputLabel={t('views.auth.password')}
                    inputName="password"
                    inputValue={compagny.password}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    inputContainerClass={compagny.isValidMail ? 'mb-3 ' : 'mt-2'}
                    withIcon={true}
                    iconClass="text-theme"
                />

                <Buttons
                    buttonType="submit"
                    name={t('views.auth.signUp')}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleSubmit(event)}
                    isLoading={compagny.isSubmit}
                    btnClass="btn-theme w-100 mt-2"
                    labelClass="text-white fw-bold"
                    styleProps={{ height: 45 }}
                    arrowVisible={false}
                    spinnerColor={"text-warning"}
                    arrowColor=''
                />

                <p className="mt-2 text-light">
                    {t('views.auth.alreadyHaveAccount')} <Link to={Routes.AUTH_LOGIN} className="text-decoration-none text-warning">{t('views.auth.logIn')}</Link>
                </p>
            </form>

            <ButtonSocialeNetwork t={t} />
        </div>
    )
}

export default UsersSignin;