//React
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


//App component
import { Buttons, InputForm, TextAreaForm } from "../../components";
import { FamlaServices } from "../../services";
import { useAppSelector } from "../../stores";
import { Colors, Images } from "../../constants";

//interface
import { iAlert } from "../../interfaces";
interface StateType {
  message: string;
  isLoading: boolean;
  name: string;
  email: string;
  subject: string;
  isSubmit: boolean;
  sendCopy: boolean;
}

const ContactUs = () => {
  const users = useAppSelector((state) => state.auth);
  const { t } = useTranslation()
  const [state, setState] = React.useState<StateType>({
    isLoading: true,
    message: "",
    name: "",
    email: "",
    isSubmit: false,
    subject: "",
    sendCopy: false,
  });

  const [alert, setAlert] = React.useState<iAlert>({
    isActive: false,
    type: "",
    message: "",
  });

  const handleChange = (event: any) => {
    const { type, name, value, checked } = event.target;
    let fieldValue = type === "checkbox" ? checked : value;

    if (type === "number") {
      fieldValue = Number(fieldValue);
    }
    if (name === "name") {
      setState({ ...state, name: fieldValue });
    }
    if (name === "email") {
      setState((prev) => ({ ...prev, email: fieldValue }));
    } else if (name === "subject") {
      setState({ ...state, subject: fieldValue });
    } else if (name === "area") {
      setState({ ...state, message: fieldValue });
    } else if (name === "sendCopy") {
      setState({ ...state, sendCopy: fieldValue });
    }
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (state.email.length === 0) {
      setAlert({
        isActive: true,
        type: "danger",
        message: "Enter a valide email",
      });
      return;
    }

    setState({ ...state, isSubmit: true });

    let formData = {
      name: state.name,
      email: state.email,
      subject: state.subject,
      message: state.message,
    };

    let data = await FamlaServices(
      "api_host",
      `/contact_us/`,
      "POST",
      formData,
      users.access_token
    );

    if (data.status === 200) {
      setAlert({
        isActive: true,
        type: "success",
        message: "Information to be sent with success",
      });
      setState((prevState) => ({
        ...prevState,
        isSubmit: false,
        sendCopy: false,
        email: "",
        subject: "",
        message: "",
      }));
    } else if (data.status === 400) {
      setAlert({
        isActive: true,
        type: "danger",
        message: data.body.description,
      });
    } else if (data.status === 401) {
      setAlert({
        isActive: true,
        type: "danger",
        message: data.body.description,
      });
    } else {
      setAlert({
        isActive: true,
        type: "danger",
        message: "An error has occurred please try again later",
      });
    }
    setState({
      ...state,
      isSubmit: false,
      email: "",
      subject: "",
      message: "",
      sendCopy: false,
    });
  };

  return (
    <div className="h-100" style={{ backgroundImage: `url(${Images.bg_2})`, backgroundSize: "cover", opacity: "1", height: "100vh" }}>
      <div className="vh-100 pt-3" style={{ backgroundColor: Colors.bg_dark_blur_2, height: "100vh" }}>
        <img className="img-fluid" src={Images.famla} alt="famla" style={{ height: 30 }} />
        <div className="container ">
          <div className="row text-white ">
            <div className="col-sm-12 col-md-6 ">
              <div className="my-5 fw-bold">
                <p className="text-white fs-2 py-5">
                  {" "}
                  <Link className="text-decoration-none text-white" to={"/dashboard"}>
                    <i className="fa-solid fa-arrow-left fs-3 me-2"></i>
                  </Link>
                  {t('navigation.contactUs')}
                </p>
                <span className="text-theme fs-3 fw-bold">
                  {t('views.legalDocs.contact.gettingInTouch')}
                </span>
                <div className="fs-5">
                  <p className="text-white my-4">
                    {t('views.legalDocs.contact.eitherFill')}
                  </p>
                  <div className="bottom-0">
                    <div>contact@famla.ai</div>
                    <span>+35319609289</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 pt-5">
              <div className="shadow p-3 mb-5 bg-dark rounded pt-5">
                <InputForm
                  inputType="text"
                  inputLabel={t('words.name')}
                  inputName="name"
                  inputValue={state.name}
                  isFloatingLabel={true}
                  labelClass="text-theme"
                  onChange={(e) => handleChange(e)}
                  inputContainerClass={"mb-3 p-0"}
                  inputClass="bg-dark text-light bg-black"
                  inputStyle={{ height: 0, padding: 0 }}
                />

                <InputForm
                  inputType="email"
                  inputLabel={t('words.email')}
                  inputName="email"
                  inputValue={state.email}
                  isFloatingLabel={true}
                  labelClass="text-theme"
                  onChange={(e) => handleChange(e)}
                  inputContainerClass={"mb-3 p-0"}
                  inputClass="bg-dark text-light bg-black"
                  inputStyle={{ height: 0, padding: 0 }}
                />
                <InputForm
                  inputType="subject"
                  inputLabel={t('words.subject')}
                  inputName="subject"
                  inputValue={state.subject}
                  isFloatingLabel={true}
                  labelClass="text-theme"
                  onChange={(e) => handleChange(e)}
                  inputContainerClass={"mb-3 p-0"}
                  inputClass="bg-dark text-light bg-black"
                  inputStyle={{ height: 0, padding: 0 }}
                />

                <TextAreaForm
                  name="area"
                  sendButton={false}
                  button={false}
                  label=""
                  rows={1}
                  classNameArea="w-100 bg-dark text-light bg-black px-3 rounded-3 "
                  classNameContainer="w-100"
                  placeholder={t('words.message') + '...'}
                  onChange={(e) => { handleChange(e); }}
                  value={state.message}
                  interviewId=""
                  sendAnswer={(e) => { }}
                  spinner={false}
                  maxRows={5}
                  minRows={6}
                  readOnly={false}
                />

                {alert.isActive && (
                  <div
                    className={`alert alert-${alert.type} alert-dismissible fade show`}
                    role="alert"
                  >
                    {alert.message}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                )}

                <div className="form-check py-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="sendCopy"
                    checked={state.sendCopy}
                    id="flexCheckDefault"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {t('views.legalDocs.contact.sendMeCopy')}
                  </label>
                </div>

                <div className="d-flex justify-content-end">
                  <Buttons
                    buttonType="submit"
                    name={t('words.send')}
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    isLoading={state.isSubmit}
                    btnClass="btn-theme ms-2 bg-theme border*theme w-25 mail-to"
                    labelClass="text-white fw-bold"
                    styleProps={{ height: 40 }}
                    withIcon={false}
                    iconName=""
                    btnStyle={{}}
                    arrowVisible={false}
                    arrowColor="text-theme pt-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
