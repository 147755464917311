// React Component
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// App Component
import { Buttons, InputForm, SelectForm } from '../../../components'
import { Routes } from '../../../routes'
import { iAlert } from '../../../interfaces'
import { FamlaServices } from '../../../services'
import { useAppSelector } from '../../../stores'

interface ownerUser {
    _id: string;
    profile: string | null;
    fullname: string;
    email: string;
    phone_number: string | null;
};

const GroupOfProcessCreate: React.FC = () => {
    const { t } = useTranslation();
    const users = useAppSelector((state) => state.auth);
    const navigate = useNavigate()
    const [ownerUsers, setOwnerUsers] = React.useState<ownerUser[]>([])
    const [newProcess, setNewProcess] = React.useState({
        name: '',
        description: '',
        industry: '',
        owner: '',
        isCollaborator: true,
        isSubmit: false,
        myRole: ''
    });
    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })

    const selectOption = ownerUsers.map((owner) => ({
        label: owner.fullname, value: owner.fullname

    }))

    React.useEffect(() => {
        const getMyRole = async () => {
            let data = await FamlaServices("api_host", `/users/my/role`, "GET", "", users.access_token);
            if (data.status === 200) {
                setNewProcess((prev) => ({ ...prev, myRole: data.body.data }))
            }
        }

        const getAllUser = async () => {
            let data = await FamlaServices("api_host", `/users/`, "GET", "", users.access_token);
            if (data.status === 200) {
                setOwnerUsers(data.body.data)
            }
        }

        getMyRole()
        getAllUser()

    }, [users.access_token])

    const handleChange = (event: any) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'name') {
            setNewProcess({ ...newProcess, name: fieldValue })
        } else {
            setNewProcess({ ...newProcess, description: fieldValue })
        }
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (newProcess.name.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('form.nameRequired') });
            return;
        }
        let formData = {
            title: newProcess.name,
            description: newProcess.description,
            created_at: Date.now()
        }

        setNewProcess({ ...newProcess, isSubmit: true });
        let data = await FamlaServices("api_host", `/project`, "POST", formData, users.access_token);
        console.log(data);

        if (data.status === 201) {
            setAlert({ isActive: true, type: 'success', message: t('form.createGroup.groupCreateSuccessfully') });
            setNewProcess({ ...newProcess, name: '', description: '', isSubmit: false });
            navigate(Routes.DASHBOARD);
        }
        else if (data.status === 400 && data.body.description === "project name already exist") {
            setAlert({ isActive: true, type: 'danger', message: t('form.createGroup.folderNameExist') });

        }
        else if (data.status === 400 && data.body.description !== "project name already exist") {
            setAlert({ isActive: true, type: 'danger', message: t('form.createGroup.youNotAuthorized') });

        } else if (data.status === 401) {
            setAlert({ isActive: true, type: 'danger', message: t('form.createGroup.youAreNotLoggedIn') });

        }
        else {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.anErrorOcured') });
        }
        setNewProcess({ ...newProcess, isSubmit: false });
    };


    return (
        <div className="w-50" style={{ margin: "auto" }}>
            <div className="mb-5" style={{ marginTop: "20%" }}>
                <Link to={Routes.DASHBOARD} className="text-light fw-bold fs-3 text-decoration-none">
                    <i className="fa-solid fa-arrow-left "></i> {t('form.createGroup.createGroup')}
                </Link>
            </div>

            <div className="mt-4">
                <InputForm
                    inputType="text"
                    inputLabel={t('words.name')}
                    inputName="name"
                    inputValue={newProcess.name}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) => handleChange(e)}
                    inputContainerClass={'mb-3'}
                    inputClass="bg-dark text-light text-bg-dark"
                />
                <InputForm
                    inputType="text"
                    inputLabel={t('words.description')}
                    inputName="description"
                    inputValue={newProcess.description}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) => handleChange(e)}
                    inputContainerClass={'mb-3'}
                    inputClass="bg-dark text-light text-bg-dark"
                />
                {newProcess.myRole === 'ADMIN' &&
                    <div className="d-flex flex-row">
                        <SelectForm
                            selectClass="mb-3 bg-dark"
                            placeholder={t('form.createGroup.selectOwner')}
                            selectName='selectOwner'
                            selectOptions={selectOption}
                            onChange={(e) => handleChange(e)}
                        />
                        {/* <Buttons
                            buttonType="submit"
                            name=""
                            onClick={() => { }}
                            isLoading={false}
                            btnClass="btn-theme ms-2"
                            labelClass="text-white fw-bold"
                            styleProps={{ height: 56 }}
                            withIcon={true}
                            iconName="fa-solid fa-paperclip"
                            btnStyle={{ height: 58 }}
                            arrowVisible={false}
                            arrowColor=''
                        /> */}
                    </div>}

                {alert.isActive &&
                    <div className={`alert alert-${alert.type}`} role="alert">
                        {alert.message}
                    </div>
                }
                <Buttons
                    buttonType="submit"
                    name={t('words.create')}
                    onClick={(e) => { handleSubmit(e) }}
                    isLoading={newProcess.isSubmit}
                    btnClass="btn-theme mt-5 w-100"
                    labelClass="text-white fw-bold fs-5 "
                    btnStyle={{ height: 68 }}
                    arrowVisible={true}
                    arrowColor='text-white'
                />
            </div>
        </div>
    )
}

export default GroupOfProcessCreate