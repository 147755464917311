import React from 'react'
import TextareaAutosize from 'react-textarea-autosize';


import Buttons from './Buttons'



interface textAreaProps {
    label: string
    rows: number
    classNameArea: string
    classNameContainer: string
    placeholder: string
    onChange(event: React.SyntheticEvent): any
    value: string
    interviewId: string
    sendAnswer: (e: React.MouseEvent<HTMLButtonElement>, answerId: string) => void;
    spinner: boolean
    maxRows: number
    minRows: number
    sendButton: boolean
    button: boolean
    readOnly: boolean,
    name: string

}





const TextAreaForm = (props: textAreaProps) => {
    const { button, name, sendButton, label, rows, classNameArea, classNameContainer, placeholder, onChange, value, interviewId, sendAnswer, spinner, maxRows, minRows, readOnly } = props

    return (
        <div className={`mb-3 ${classNameContainer} position-relative`}>
            <label htmlFor="textarea" className="form-label">{label}</label>
            {button &&
                <Buttons
                    buttonType="submit"
                    name=""
                    onClick={(e) => { sendAnswer(e, interviewId) }}
                    isLoading={false}
                    btnClass={`btn-theme ms-2 position-absolute end-0 me-2 ${(spinner || !sendButton) && 'disabled'} `}
                    labelClass="text-white fw-bold"
                    styleProps={{ height: 30, marginTop: 29.5 }}
                    withIcon={true}
                    iconName={`fa-solid ${spinner && 'fa-spinner'} fa-arrow-up fs-6`}
                    btnStyle={{ height: 58 }}
                    arrowVisible={false}
                    arrowColor=''
                />}

            <TextareaAutosize
                minRows={minRows}
                maxRows={maxRows}
                className={`border-light ${classNameArea}`}
                placeholder={placeholder}
                onChange={(event) => onChange(event)}
                value={value}
                name={name}
                id="textarea"
                rows={rows}
                style={{ resize: 'none', overflow: 'auto' }}
                readOnly={readOnly}
                maxLength={1600}

            />
        </div>
    )
}
export default TextAreaForm