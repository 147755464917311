// Recat Component
import { PURGE } from 'redux-persist'
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

// Interface

interface AuthState {
    role: string;
}

const initialState: AuthState = {
    role: "",
}

const roleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {
        role: (state, action: PayloadAction<{ role: string }>) => {
            state.role = action.payload.role;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.role = '';
        })
    }
})

export default roleSlice.reducer;
export const { role } = roleSlice.actions; 
