// React Component
import React from 'react'
import { Link } from 'react-router-dom'

interface InputProps {
    formClass?: string
    iconClass?: string
    iconName?: string
    inputClass?: string
    inputContainerClass?: string
    inputLabel?: string
    inputName: string
    inputStyle?: React.CSSProperties
    inputType: string
    inputValue?: string
    isFloatingLabel?: boolean
    onChange(event: React.SyntheticEvent): any
    labelClass?: string
    placeholderValue?: string
    withIcon?: boolean
    activeFocusRef?: React.RefObject<HTMLInputElement> | undefined;
    simpleInput?: boolean
}

const InputForm = (props: InputProps) => {
    const [securePassword, setSecurePassword] = React.useState<boolean>(true);
    const { activeFocusRef, formClass, iconClass, iconName, inputClass, inputContainerClass, inputLabel, inputName, inputStyle, inputType, inputValue, isFloatingLabel, onChange, labelClass, placeholderValue, withIcon, simpleInput } = props

    const toggleSecurePassword = () => {
        setSecurePassword(!securePassword)
    }

    const render = () => {
        if (withIcon && isFloatingLabel) {
            return (
                <div className={`d-flex flex-row align-items-center ${inputContainerClass}`}>
                    <div className={`form-floating w-100 ${formClass}`}>
                        <input ref={activeFocusRef} type={inputType === "password" && !securePassword ? "text" : inputType} className={`form-control ${inputClass}`} id={inputName} name={inputName} value={inputValue} onChange={(event) => onChange(event)} placeholder={placeholderValue} />
                        <label htmlFor={inputName} className={labelClass}>{inputLabel}</label>
                    </div>
                    {inputType === "password" ?
                        <span className="z-3" onClick={toggleSecurePassword}>
                            <div style={{ marginLeft: -25, marginBottom: 0, zIndex: 100 }}>
                                {securePassword ?
                                    <i className={`fa-solid fa-eye ${iconClass}`}></i> :
                                    <i className={`fa-solid fa-eye-slash ${iconClass}`}></i>
                                }
                            </div>
                        </span> :
                        <div style={{ marginBottom: 10, zIndex: 100 }}>
                            <i className={`${iconName} ${iconClass}`}></i>
                        </div>
                    }
                </div>
            )
        }
        else if (withIcon && !isFloatingLabel) {
            return (
                <div className={`d-flex flex-row align-items-center ${inputContainerClass}`}>
                    <div className={`w-100 ${formClass}`}>
                        <label htmlFor={inputName} className={labelClass}></label>
                        <input ref={activeFocusRef} type={inputType === "password" && !securePassword ? "text" : inputType} className={`form-control form-control-lg ${inputClass}`} id={inputName} name={inputName} value={inputValue} placeholder={placeholderValue} onChange={(event) => onChange(event)} style={inputStyle} />
                    </div>
                    {inputType === "password" ?
                        <Link to={``} className="z-3" onClick={toggleSecurePassword}>
                            <div style={{ marginLeft: -25, marginTop: 10, zIndex: 100 }}>
                                {securePassword ?
                                    <i className={`fa-solid fa-eye ${iconClass}`}></i> :
                                    <i className={`fa-solid fa-eye-slash ${iconClass}`}></i>
                                }
                            </div>
                        </Link> :
                        <div style={{ marginLeft: ' -97%', marginTop: 15, zIndex: 100 }}>
                            <i className={`${iconName} ${iconClass}`}></i>
                        </div>
                    }
                </div>
            )
        }
        else if (!withIcon && !isFloatingLabel) {
            return (
                <div className={`w-100 ${inputContainerClass}`}>
                    <label htmlFor={inputName} className={labelClass}>{inputLabel}</label>
                    <input ref={activeFocusRef} type={inputType} className={`form-control form-control-lg ${inputClass}`} id={inputName} name={inputName} value={inputValue} placeholder={placeholderValue} onChange={(event) => onChange(event)} style={inputStyle} />
                </div>
            )
        } else if (simpleInput) {
            return (
                <div className={`w-100 ${inputContainerClass}`}>
                    <input ref={activeFocusRef} type={inputType} className={`form-control ${inputClass}`} value={inputValue} id={inputName} name={inputName} placeholder={""} onChange={(event) => onChange(event)} />
                </div>
            )
        }

        return (
            <div className={`form-floating w-100 ${inputContainerClass}`}>
                <input ref={activeFocusRef} type={inputType} className={`form-control ${inputClass}`} id={inputName} name={inputName} value={inputValue} onChange={(event) => onChange(event)} placeholder="" />
                <label htmlFor={inputName} className={labelClass}>{inputLabel}</label>
            </div>
        )
    }

    return (
        render()
    )
}

export default InputForm