// React Component
import React from 'react'

interface Options {
    value: string
    label: string

}

interface SelectProps {
    onChange(event: React.SyntheticEvent): any
    placeholder: string
    selectClass?: string
    selectName: string
    selectOptions: Array<Options>
    defaultValue?: string
}

const SelectForm = (props: SelectProps) => {
    const { selectClass, onChange, placeholder, selectName, selectOptions, defaultValue } = props

    return (
        <select className={`form-select form-select-lg text-theme ${selectClass}`} value={defaultValue} name={selectName} onChange={(event) => onChange(event)} style={{ fontSize: "1rem", height: 58 }}>
            <option selected disabled>{placeholder}</option>
            {selectOptions.map((option) => (
                <option value={option.value} key={option.value}>{option.label}</option>
            ))}
        </select>
    )
}

export default SelectForm