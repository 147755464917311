//React
import React from 'react'

//
import { Routes } from '../../routes'

//
interface ChildProps {
    newElement: string
    create: string
    noResult: boolean
}


const NewProcessOrGroup = (props: ChildProps) => {
    const { newElement, create, noResult } = props
    return (
        <div className='mt-3'>
            {!noResult ?
                <a href={create === "process" ? Routes.PROCESS_CREATE : Routes.CREATE_GROUP_PROCESS} className="cardProcess   bg-black border border-white text-white d-flex flex-column align-items-center pt-4 text-decoration-none">
                    <span className='mb-2'><i className="fa-solid fa-plus fs-5"></i></span>
                    <span className='px-3'>{newElement}</span>
                </a>
                :
                <div className=" cardProcess  bg-black border border-white text-white d-flex flex-column align-items-center pt-4 text-decoration-none">
                    <span className='mb-2'><i className="fa-solid fa-ban fs-5"></i></span>
                    <span className='px-3'>{newElement}</span>
                </div>}
        </div>
    )
}
export default NewProcessOrGroup