// React Component
import React, { useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"

// App Component
import { NavLayout } from "../../../layouts"
import { Routes } from "../../../routes"
import { FamlaServices } from "../../../services"
import { useAppSelector } from "../../../stores"
import { ApprovalModal, ChangeLanguageModal, InviteUser, ShareModal } from "../../../views/modal"
import EditModal from "../../../views/modal/editModal"
// import { HeaderInterview } from "../../../views"
import HeaderInterview from "../../../views/interview/header"
import ChatBot from "../../../views/interview/chatBot"


//interface
import { iInterview } from "../../../components/type"
import { iCollaboration } from "../../../components/type"

interface StateType {
    interviews: iInterview[];
    sProcess: iInterview[];
    collaborators: iCollaboration[];
    processName: string;
    procesId: string;
    interviewId: string;
    isLoading: boolean
    processTitle: string
    email: string,
    isSubmit: boolean,
    role: string,
    isLoadingProcess: boolean,
    autorisation: boolean,
    generateMap: string
    isloadingCollaborators: boolean
    loadSwim: boolean
    swimlaneId: string,
    swimlaneName: string
}


const ProcessInterview = () => {
    const { id } = useParams();
    const closeRef = useRef<HTMLButtonElement>(null);
    const users = useAppSelector((state) => state.auth);
    const inviteAproval = useRef<HTMLDivElement>(null)
    const shareRef = useRef<HTMLDivElement>(null)
    const editRef = useRef<HTMLDivElement>(null)
    const closeOffCanvas = useRef(null)
    const [invited, setInvited] = React.useState<string>();
    const [swimlanes, setSwimlanes] = useState<any[]>([]);
    const [collaborator, setCollaborator] = useState(users.user);
    const [time, setTime] = useState(0);
    const [state, setState] = React.useState<StateType>({
        interviews: [],
        collaborators: [],
        processName: '',
        procesId: '',
        interviewId: "",
        isLoading: true,
        sProcess: [],
        processTitle: "",
        email: "",
        isSubmit: false,
        role: "",
        isLoadingProcess: true,
        isloadingCollaborators: true,
        autorisation: true,
        generateMap: "",
        loadSwim: true,
        swimlaneId: "",
        swimlaneName: ""
    })



    const [interviewwId, setInterviewwId] = useState(id)

    const updateInterviewwId = (newId: string | undefined) => {
        setInterviewwId(newId);

    };
    const updateCollaborators = (col: any) => {
        setCollaborator(col);
    };

    React.useEffect(() => {

        const getAutorisationOfUser = async () => {
            let data = await FamlaServices("api_host", `/interview/${interviewwId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                setState((prev) => ({ ...prev, autorisation: data.body.data }))
            }
        }
        getAutorisationOfUser();

        const loadTime = async (pId: string) => {
            let data = await FamlaServices("api_host", `/stats/process/${pId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                setTime(data.body.data);
            }
        };

        const loadAllSwimlane = async (id: string) => {
            let data = await FamlaServices("api_host", `/mapping/process/${id}`, "GET", "", users.access_token);
            if (data.status === 200) {
                const datas = data.body.data;
                const dataReverse = datas.reverse();
                setState((prev) => ({ ...prev, loadSwim: false }))
                setSwimlanes(dataReverse);
            } else {
                setState((prev) => ({ ...prev, loadSwim: false }))
            }
            loadTime(id);
        };

        const getProcessByUser = async () => {
            let data = await FamlaServices("api_host", `/process/my`, "GET", "", users.access_token);
            if (data.status === 200) {
                setTimeout(() => {
                    setState((prev) => ({ ...prev, collaborators: data.body.data, isloadingCollaborators: false }))
                }, 500);
            }
        }
        getProcessByUser()

        const getRoleByprocess = async (processId: string) => {
            let data = await FamlaServices("api_host", `/process/my/sub/${processId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                setState((prev) => ({ ...prev, sProcess: data.body.data, isLoadingProcess: false }))
            }
        }

        const getInterviewById = async () => {
            let data = await FamlaServices("api_host", `/interview/by/id/${interviewwId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                let processId = data.body.data?.collaboration.process?._id
                let owner = data.body.data?.collaboration.process?.owner
                updateNotificationProcess(processId);
                loadAllSwimlane(processId);
                setState((prev) => ({ ...prev, interviews: data.body.data, processTitle: owner.fullname, procesId: processId }))
                getRoleByprocess(processId)
            }
        }

        getInterviewById()

        const updateNotificationProcess = async (processId: string) => {
            FamlaServices("api_host", `/notification/${processId}`, "PUT", "", users.access_token);
        }

    }, [id, interviewwId, state.interviewId, state.procesId, users.access_token])


    const deleteInterviews = async (interviews_id: string) => {
        setState(prev => {
            const updatedInterviews = prev.sProcess.filter((interview: any) => interview._id !== interviews_id);
            return { ...prev, sProcess: updatedInterviews };
        });

        try {
            await FamlaServices("api_host", `/interview/${interviews_id}`, "DELETE", "", users.access_token);
        } catch (error) {
            setState(prev => {
                return { ...prev, isLoadingProcess: false };
            });
        }
    };

    const deleteSwimlaneDiagram = async (swimlaneId: string) => {
        setSwimlanes((prev) => prev.filter((swimlane) => swimlane._id !== swimlaneId));
        try {
            await FamlaServices("api_host", `/mapping/${swimlaneId}`, "DELETE", "", users.access_token);
        } catch (error) {
            setState(prev => {
                return { ...prev, isLoadingProcess: false };
            });
        }
    };


    function setModalWithSwimlaneId(id: string, action: string) {

        setState((prev) => ({ ...prev, swimlaneId: id, swimlaneName: action }))

        if (action === "aproval") {
            inviteAproval.current && inviteAproval.current.click();

        } else if (action === "share") {
            shareRef.current && shareRef.current.click()
        } else {
            editRef.current && editRef.current.click()
        }
    }


    return (
        <>
            <div className="d-flex justify-content-between bg-secondary fixed-top h-100 bg-black">

                <div className="m-none h-100 " style={{ backgroundColor: "#1F1F21", width: "332px" }}>
                    <NavLayout
                        processes={state.sProcess}
                        recentProcess={state.collaborators}
                        processName={state.processTitle}
                        updateInterviewwId={updateInterviewwId}
                        updateCollaborators={updateCollaborators}
                        interviwsId={id}
                        isLoadingProcess={state.isLoadingProcess}
                        isloadingCollaborators={state.isloadingCollaborators}
                        closeOffCanvas={''}
                        deleteInterviews={(id: string) => { deleteInterviews(id) }}
                        deleteSwimlaneDiagram={(id: string) => { deleteSwimlaneDiagram(id) }}
                        swimlanes={swimlanes}
                        processId={state.procesId}
                        loadDiagram={state.loadSwim}
                        setModalWithSwimlaneId={setModalWithSwimlaneId}
                        renameSwimlane={setModalWithSwimlaneId}
                        shareSwimlane={setModalWithSwimlaneId}
                    />
                </div>

                <div className="container vh-100  bg-black w-100">
                    <HeaderInterview />
                    <ChatBot
                        time={time}
                        interviewwId={interviewwId}
                        id={id}
                        setInvited={setInvited}
                        swimlanes={swimlanes}
                        collaborator={collaborator}
                        procesId={state.procesId}
                    />

                </div>
            </div>
            <div className="offcanvas offcanvas-start bg-dark" id="offcanvas" aria-labelledby="offcanvasLabel">
                <div className="offcanvas-header">
                    <Link to={Routes.DASHBOARD} className="text-light fw-bold fs-5 text-decoration-none ">
                        <i className="fa-solid fa-arrow-left"></i> Processes / Collaborators
                    </Link>
                    <button ref={closeOffCanvas} type="button" className="btn-close text-reset bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="w-100 ">
                        <NavLayout
                            processes={state.sProcess}
                            recentProcess={state.collaborators}
                            processName={state.processTitle}
                            updateInterviewwId={updateInterviewwId}
                            updateCollaborators={updateCollaborators}
                            interviwsId={id}
                            isLoadingProcess={state.isLoadingProcess}
                            isloadingCollaborators={state.isloadingCollaborators}
                            closeOffCanvas={closeOffCanvas}
                            deleteInterviews={(id: string) => { deleteInterviews(id) }}
                            deleteSwimlaneDiagram={(id: string) => { deleteSwimlaneDiagram(id) }}
                            swimlanes={swimlanes}
                            processId={state.procesId}
                            loadDiagram={state.loadSwim}
                            setModalWithSwimlaneId={setModalWithSwimlaneId}
                            renameSwimlane={setModalWithSwimlaneId}
                            shareSwimlane={setModalWithSwimlaneId}

                        />

                    </div>
                </div>
            </div>

            <span ref={inviteAproval} data-bs-target={`#approval${state.swimlaneId}`} data-bs-toggle='modal'></span>
            <span ref={shareRef} data-bs-target={`#shareModal${state.swimlaneId}`} data-bs-toggle='modal'></span>
            <span ref={editRef} data-bs-target={`#edit${state.swimlaneId}`} data-bs-toggle='modal'></span>

            <InviteUser
                processId={state.procesId}
                closeRef={closeRef}
                interviewwId={interviewwId}
                invited={invited}
            />
            <ApprovalModal swimlaneId={state.swimlaneId} />
            <EditModal
                swimlaneId={state.swimlaneId}
                processId={state.procesId}
                setProject={setSwimlanes}
                processName={state.swimlaneName}
            />
            <ShareModal swimlaneId={state.swimlaneId} processId={state.procesId} />
            <ChangeLanguageModal />

        </>
    )
}

export default ProcessInterview