import React, { useState, useEffect } from 'react';

//App
import { slice } from '../../utils';
import Spiner from '../Spiner';
import { Routes } from '../../routes';
import { FamlaServices } from '../../services';
import { useAppSelector } from '../../stores';
import { formatDateTime } from '../../utils';
import { TFunction } from 'i18next';
import InputForm from '../form/InputForm';
import Buttons from '../form/Buttons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//interface
interface ChildProps {
    isLoading: boolean;
    list: any;
    t: TFunction
}

const ProcessList = (props: ChildProps) => {
    const { isLoading, list, t } = props;
    const users = useAppSelector((state) => state.auth);
    const [mapStatus, setMapStatus] = useState<{ [key: string]: boolean }>({});
    const [interviewTimes, setInterviewTimes] = useState<(string | null)[]>([]);
    const [editableData, setEditableData] = useState<{
        [key: string]: {
            next_revision_date: string;
            metrics: string;
            revision_triggers: string;
        }
    }>({});
    const [loadingProcesses, setLoadingProcesses] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const loadAllMaps = async () => {
            const status: { [key: string]: boolean } = {};
            for (const process of list) {
                status[process.Process._id] = await loadMap(process.Process._id);
            }
            setMapStatus(status);
        };

        if (!isLoading) {
            loadAllMaps();
        }

        const loadInterviewTimes = async () => {
            const times = await Promise.all(
                list.map(async (data: any) => {
                    const time = await loadTime(data.Process._id);
                    return time;
                })
            );
            setInterviewTimes(times);
        };

        loadInterviewTimes();

        if (!isLoading) {
            const initialData: { [key: string]: { next_revision_date: string; metrics: string; revision_triggers: string; } } = {};
            list.forEach((data: any) => {
                initialData[data.Process._id] = {
                    next_revision_date: data.Process?.next_revision_date || "",
                    metrics: data.Process?.metrics || "",
                    revision_triggers: data.Process?.revision_triggers || ""
                };
            });
            setEditableData(initialData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, list]);

    const loadMap = async (processId: string) => {
        try {
            let data = await FamlaServices("api_host", `/mapping/${processId}`, "GET", "", users.access_token);
            return data.status === 200;
        } catch (e) {
            return false;
        }
    };

    const loadTime = async (processId: string): Promise<any> => {
        let data = await FamlaServices("api_host", `/stats/process/${processId}`, "GET", "", users.access_token);
        if (data.status === 200) {
            return data.body.data;
        }
        return null;
    };

    const updateProcessData = async (processId: string) => {
        setLoadingProcesses(prev => ({ ...prev, [processId]: true }));
        try {
            const updatedData = editableData[processId];
            const response = await FamlaServices("api_host", `/process/update/info/${processId}`, "PUT", updatedData, users.access_token);
            if (response.status === 200) {
                setLoadingProcesses(prev => ({ ...prev, [processId]: false }));
            }
        } catch (error) {
        }
        setLoadingProcesses(prev => ({ ...prev, [processId]: false }));
    };

    const handleChange = (processId: string, fieldName: string, value: string | Date) => {
        setEditableData((prevData) => {
            const currentProcessData = prevData[processId] || {};

            const updatedFieldValue = fieldName === 'next_revision_date' && value instanceof Date
                ? (value as Date).toISOString().replace('T', ' ').split('.')[0]
                : value;

            return {
                ...prevData,
                [processId]: {
                    ...currentProcessData,
                    [fieldName]: updatedFieldValue,
                },
            };
        });
    };

    const handleDateChange = (processId: string, date: Date) => {
        handleChange(processId, 'next_revision_date', date);
    };

    const handleInputChange = (processId: string, fieldName: string, value: string) => {
        handleChange(processId, fieldName, value);
    };


    return (
        <>
            <table className="table mb-0 text-nowrap pt-0 w-100" >
                <thead className="w-100 fix-sidebar mb-2 top-0 pt-0 w-auto">
                    <tr className='table-theme'>
                        <th className="border-0">{t('views.processByGroupView.processName')}</th>
                        <th className="border-0">{t('views.processByGroupView.ownerName')}</th>
                        <th className="border-0">{t('views.processByGroupView.lastModifiedDate')}</th>
                        <th className="border-0">{t('views.processByGroupView.diagramLink')}</th>
                        <th className="border-0">{t('views.processByGroupView.approvalName')}</th>
                        <th className="border-0">{t('views.processByGroupView.approvalDate')}</th>
                        <th className="border-0">{t('views.processByGroupView.interviewTime')}</th>
                        <th className="border-0">{t('views.processByGroupView.nexRevisionDate')}</th>
                        <th className="border-0">{t('views.processByGroupView.processMetrics')}</th>
                        <th className="border-0">{t('views.processByGroupView.revisionTriggers')}</th>
                        <th className="border-0">{t('words.save')}</th>
                    </tr>
                </thead>
                {isLoading ? (
                    <tr className='align-items-start'>
                        <td colSpan={11} className="text-start pt-3">
                            <Spiner className='bg-black' />
                        </td>
                    </tr>
                ) : (
                    <tbody className='small' style={{ width: "100%" }} >
                        {list && list.length === 0 && !isLoading ? (
                            <tr className='bg-black text-white'>
                                <th colSpan={11} className="text-start fs-5 bg-black text-white border-0">{t('views.processByGroupView.noProcess')}</th>
                            </tr>
                        ) : (
                            list.map((data: any, index: number) => {
                                const approver = data.swimlane?.approver;
                                const interviewTime = interviewTimes[index];
                                return (
                                    <tr className='small ms-1 table-dark w-100 ps-1 fs-6' key={data._id}>
                                        <td className="align-middle border-top-0">
                                            {slice(data.Process.title, 20)}
                                        </td>
                                        <td className="align-middle border-top-0">
                                            <span>{data.Process.owner.fullname}</span>
                                        </td>
                                        <td className="align-middle border-top-0">
                                            <span>{formatDateTime(data.Process.created_at)}</span>
                                        </td>
                                        <td className="align-middle border-top-0">
                                            {mapStatus[data.Process._id] ? (
                                                <a href={`${Routes.MAPPING}${data.Process._id}/${data.swimlane?._id}`}>{slice(data.Process.description, 20)}</a>
                                            ) : (
                                                <span>{slice(data.swimlane?.name, 20)}</span>
                                            )}
                                        </td>
                                        <td className="align-middle border-top-0">
                                            <span>{approver?.fullname ? approver.fullname : approver?.email}</span>
                                        </td>
                                        <td className="align-middle border-top-0">
                                            <span>{approver ? formatDateTime(data.swimlane?.updated_at) : ""}</span>
                                        </td>
                                        <td className="align-middle border-top-0">
                                            <span>{interviewTime}{interviewTime && ' Minutes'}</span>
                                        </td>
                                        <td className="align-middle border-top-0">
                                            <DatePicker
                                                selected={editableData[data.Process._id]?.next_revision_date ? new Date(editableData[data.Process._id]?.next_revision_date) : null}
                                                onChange={(date) => handleDateChange(data.Process._id, date as Date)}
                                                showTimeSelect
                                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                                className="bg-black text-light form-control"
                                            />
                                        </td>
                                        <td className="align-middle border-top-0">
                                            <InputForm
                                                inputType="text"
                                                inputLabel={t('views.processByGroupView.processMetrics')}
                                                inputName="metrics"
                                                inputValue={editableData[data.Process._id]?.metrics || ""}
                                                isFloatingLabel={true}
                                                labelClass="text-theme"
                                                onChange={(e) => handleInputChange(data.Process._id, 'metrics', (e.target as HTMLInputElement).value)}
                                                inputContainerClass={''}
                                                simpleInput={true}
                                                inputClass='bg-black text-light'
                                            />
                                        </td>
                                        <td className="align-middle border-top-0">
                                            <InputForm
                                                inputType="text"
                                                inputLabel={t('views.processByGroupView.revisionTriggers')}
                                                inputName="revision_triggers"
                                                inputValue={editableData[data.Process._id]?.revision_triggers || ""}
                                                isFloatingLabel={true}
                                                labelClass="text-theme"
                                                onChange={(e) => handleInputChange(data.Process._id, 'revision_triggers', (e.target as HTMLInputElement).value)}
                                                inputContainerClass={''}
                                                simpleInput={true}
                                                inputClass='bg-black text-light'
                                            />
                                        </td>
                                        <td className="align-middle border-top-0">
                                            <Buttons
                                                buttonType="submit"
                                                name={!loadingProcesses[data.Process._id] ? t('words.save') : ""}
                                                onClick={() => updateProcessData(data.Process._id)}
                                                isLoading={false}
                                                btnClass="btn-theme"
                                                labelClass="text-light fw-bold"
                                                styleProps={{}}
                                                withIcon={loadingProcesses[data.Process._id]}
                                                iconName="fa-solid fa-spinner text-black bg-theme"
                                                btnStyle={{ height: 58 }}
                                                arrowVisible={false}
                                                arrowColor=""
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                )}
            </table>
        </>
    );
};

export default ProcessList;
