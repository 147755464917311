const Languages = [
    { value: 'english', label: 'english' },
    { value: 'french', label: 'french' },
    // { value: 'Spanish', label: 'Spanish' },
    // { value: 'German', label: 'German' },
    // { value: 'Chinese', label: 'Chinese' },
    // { value: 'Japanese', label: 'Japanese' },
    // { value: 'Korean', label: 'Korean' },
    // { value: 'Russian', label: 'Russian' },
    // { value: 'Portuguese', label: 'Portuguese' },
    // { value: 'Italian', label: 'Italian' },
    // { value: 'Arabic', label: 'Arabic' },
    // { value: 'Hindi', label: 'Hindi' },
    // { value: 'Bengali', label: 'Bengali' },
    // { value: 'Punjabi', label: 'Punjabi' },
    // { value: 'Marathi', label: 'Marathi' },
    // { value: 'Tagalog', label: 'Tagalog' },
    // { value: 'Vietnamese', label: 'Vietnamese' },
    // { value: 'Turkish', label: 'Turkish' },
    // { value: 'Dutch', label: 'Dutch' },
    // { value: 'Polish', label: 'Polish' },
];
export default Languages
