// React Component
import { Outlet } from "react-router-dom"

// Features
// import { useAppSelector } from "../stores/store"
// import Routes from "./Routes"

const PrivateRoutes = () => {
    // const auth = useAppSelector(state => state.auth)
    // console.log(auth)

    // return(
    //     typeof(auth) === 'undefined' || !auth.isAuthenticated ? 
    //         <Navigate to={`${Routes.AUTH_LOGIN}?status=10`} /> : 
    //         <Outlet />
    // )

    // TODO: supprimer apres integration de l'authentification
    return(
        <Outlet />
    )

}

export default PrivateRoutes