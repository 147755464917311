//React
import React from 'react';
import { useTranslation } from 'react-i18next';

//App
import Spiner from '../Spiner';

//Interface
import iProcess from '../type/Process';
import { slice } from '../../utils';



interface RecentProcessesListProps {
    recentProcess: iProcess[];
    isloadingCollaborators: boolean;
    navigateToNewProcess: (processId: string) => void;
}

const RecentProcessesList: React.FC<RecentProcessesListProps> = ({
    recentProcess,
    isloadingCollaborators,
    navigateToNewProcess,
}) => {
    const { t } = useTranslation();

    return (
        <div className="">
            <div className="mt-4">
                <h4 className="text-light fs-6 mb-3">{t('views.processInterview.recentProcesses')}</h4>
                <div className="overflow-auto customHeightProcess d-flex flex-column">
                    {isloadingCollaborators ? (
                        <Spiner className="spinner-border-sm ps-1" />
                    ) : (
                        <>
                            {recentProcess.map((process) => (
                                <div
                                    key={process._id}
                                    onClick={() => navigateToNewProcess(process._id)}
                                    className="text-light fs-6 text-decoration-none border-0 rounded-4 btn btn-outline-secondary btn-md"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="d-flex">
                                        <span>
                                            <i className="fa-regular fa-message small me-2"></i>
                                        </span>
                                        <span>{slice(process.title, 23)}</span>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RecentProcessesList;
