import React, { Component } from "react";
import { Spiner } from "../../../components";

class Button extends Component {
  render() {
    return (
      <div className=" me-2">
        <button className="dhx_sample-btn dhx_sample-btn--flat border-0 bg-dark" onClick={this.props.onClick} >
          {this.props.submit ?
            <Spiner className=" spinner-border-sm text-white" />
            :
            <>{this.props.name}</>
          }
        </button>
      </div>
    );
  }
}

export default Button;
