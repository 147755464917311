// React Component
import React from 'react'

// App Component
import { AppLayout } from '../../layouts'

// Features
import { ProcessInterview } from "../../features/process"


const ProcessInterviewView = () => {
    return (
        <AppLayout>
            <ProcessInterview />
        </AppLayout>
    )
}

export default ProcessInterviewView